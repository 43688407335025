import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../Hooks/authContext";
import { useEffect, useRef } from "react";
import { useLang } from "../../../Hooks/LangContext";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
const AccountMenu = ({ close }) => {
  const { lang } = useLang();
  const { account, logout } = useAuth();
  const MenuRef = useRef(null);
  const nav = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (MenuRef.current && !MenuRef.current.contains(event.target)) {
        close();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: -60 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, y: -60, transition: { duration: 0.3 } }}
      ref={MenuRef}
      className={`absolute top-12 text-black z-50 border   ${lang==="ar"?"md:left-1/2  -left-[400%]":"md:right-1/2 -right-[400%] "}  border-gray-border rounded-[20px] p-4 bg-white w-[216px] h-[220px]`}
    >
      <ul
        dir={lang === "ar" ? "rtl" : "ltr"}
        className={`text-bold text-lg ${
          lang === "ar" ? "text-right" : "text-left"
        }`}
      >
        <li className="border-b border-gray-border mb-4 pb-2 hover:px-2 duration-300 hover:text-gray-600">
          <Link to={"/investmentAinew"}>
            {lang === "ar" ? "لوحة التحكم" : "Dashboard"}
          </Link>
        </li>
        <li className="border-b border-gray-border mb-4 pb-2 hover:px-2 duration-300 hover:text-gray-600">
          <Link to={`/settings`}>
            {lang === "ar" ? "الملف الشخصي" : "Profile"}
          </Link>
        </li>
        <li className="border-b border-gray-border mb-4 pb-2 hover:px-2 duration-300 hover:text-gray-600">
          <Link to={"/FeasibilityStudies"}>
            {lang === "ar" ? "مشاريعي" : "My Projects"}
          </Link>
        </li>
        <li className="text-red-600 hover:px-2 duration-300 hover:text-red-300">
          <button
            onClick={() => {
              close();
              logout();
              nav("/");
            }}
          >
            {lang === "ar" ? "تسجيل الخروج" : "Logout"}
          </button>
        </li>
      </ul>
    </motion.div>
  );
};

AccountMenu.propTypes = {
  close: PropTypes.func,
};
export default AccountMenu;
