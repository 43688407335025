/**
 * Builds the old Arabic JSON structure using both:
 * 1) Minimal user data (for text fields).
 * 2) A complete object of numeric variables (including user inputs + "fixed" assumptions),
 *    with default values if they aren't provided.
 *
 * @param {Object} minimalData  Minimal JSON containing text fields (and possibly numeric user inputs).
 * @param {Object} numericVars  All numeric fields needed for calculations, with fallback defaults:
 *   {
 *     // User input numeric fields (defaults if missing)
 *     landPurchasePricePerSqm,   // default 5700
 *     sellingPricePerSqm,        // default 6000
 *     constructionCostPerSqm,    // default 1700
 *     floorsCount,               // default 12
 *
 *     // External numeric assumption
 *     earthArea,                 // default 2000
 *
 *     // Other "fixed" numeric assumptions
 *     groundFloorBuildRatio,     // default 0.6
 *     repeatedFloorsRatio,       // default 0.7
 *     roofAnnexRatio,            // default 0.3
 *     landscapeCostPerSquareMeter, // default 300
 *     designCost,                // default 200000
 *     legalCost,                 // default 150000
 *     siteCoordinationCost       // default 100000
 *   }
 *
 * @returns {Object} {
 *   oldData: {...},  // The old JSON structure (Arabic)
 *   variables: {...} // The final numeric variables used (with defaults where needed)
 * }
 */
export default function transformDataWithAllVariables(
  minimalData,
  numericVars = {},
  projectType = "برج إداري",
  firstMixedFloors = 2
) {
  // 1) Extract text fields from minimalData
  const introduction =
    minimalData["مقدمة"] || "ضع مقدمة مناسبة للمشروع ويجب أن تكون مفيدة";
  const title = minimalData["العنوان"] || "ضع عنوانا للمشروع هنا";

  const risks = minimalData["تقييم_المخاطر"] || {
    تقلبات_السوق: "متوسطة",
    التغييرات_التنظيمية: "مخاطر منخفضة",
    العوامل_الاقتصادية: "عالية",
  };
  const strategy = minimalData["اعتبارات_استراتيجية"] || {
    اتجاهات_السوق: "",
    توقيت_الاستثمار: "",
    التوقعات_طويلة_الأمد: "",
  };

  const userExecutiveSummary = minimalData["ملخص_تنفيذي"] || "";
  const userRecommendations = minimalData["توصيات"] || "";

  const propertySizes = minimalData["حجم_الوحدات"] || "";

  let convertedPropertySize;

  if (typeof propertySizes === "string") {
    // This regex captures digits with optional decimal part
    const match = propertySizes.match(/\d+(\.\d+)?/);

    if (match) {
      // Convert the matched substring into a float or integer
      convertedPropertySize = parseFloat(match[0]);
    } else {
      // If no digits found in the string, default to 0 (or any fallback)
      convertedPropertySize = 0;
    }
  } else {
    // If it's already a number, just take it as is (or default to 0 if undefined)
    convertedPropertySize = propertySizes ?? 0;
  }

  // 2) De-structure numeric fields from numericVars, applying defaults if missing
  let {
    // User input numeric fields (defaults)
    landPurchasePricePerSqm = 5700,
    sellingPricePerSqm = 6000,
    sellingPricePerSqm2 = 8000,
    constructionCostPerSqm = 1700,

    // Floors
    floorsCount = minimalData["الطوابق_المقترحة"],
    firstFloors = firstMixedFloors,

    // External numeric assumption (default)
    earthArea = 2000,

    // "Fixed" numeric assumptions (defaults)
    groundFloorBuildRatio = 0.6, // 60%
    repeatedFloorsRatio = 0.7, // 70%
    roofAnnexRatio = 0.3, // 30%
    landBuildRatio = 0.4, // 40%
    buildingArea = 400, // 400 sqm
    villaArea = 350, // 350sqm

    // Property Sizes "From Value to Value"
    basePropertySize = convertedPropertySize,
    propertySizesFrom = basePropertySize - 50,
    propertySizesTo = basePropertySize + 50,

    secondPropertySizeFrom,
    secondPropertySizeTo,
    secondBasePropertySize,

    landscapeCostPerSquareMeter = 300, // 300 SAR

    yearlyRentPerMeterValue = 0.18 * landPurchasePricePerSqm, // 18%
    yearlyRentPerMeterValue2 = 0.09 * landPurchasePricePerSqm, // 9%

    designCost = 200000, // SAR
    legalCost = 150000, // SAR
    siteCoordinationCost = 100000, // SAR
  } = numericVars;

  // convert floors from string to float
  if (typeof floorsCount === "string") {
    // This regex captures digits with optional decimal part
    const match = floorsCount.match(/\d+(\.\d+)?/);

    if (match) {
      // Convert the matched substring into a float or integer
      floorsCount = parseFloat(match[0]);
    } else {
      // If no digits found in the string, default to 0 (or any fallback)
      floorsCount = 0;
    }
  }

  // 3) Perform calculations
  const groundFloorAreaValue = earthArea * groundFloorBuildRatio;
  const repeatedFloorAreaValue = earthArea * repeatedFloorsRatio;
  const roofAnnexAreaValue = repeatedFloorAreaValue * roofAnnexRatio;

  let repeatedFloorsTotalArea = 0;
  if (floorsCount > 2) {
    repeatedFloorsTotalArea = repeatedFloorAreaValue * (floorsCount - 2);
  }

  const totalEffectiveBuildArea =
    groundFloorAreaValue + repeatedFloorsTotalArea + roofAnnexAreaValue;

  const landscapeAreaValue = earthArea - groundFloorAreaValue;
  const sharedSpaceArea = totalEffectiveBuildArea / 10; // 10%
  const netEffectiveBuildArea = totalEffectiveBuildArea - sharedSpaceArea;

  const buildingFactorAreaValue =
    groundFloorAreaValue + repeatedFloorsTotalArea;
  const buildingFactorValue = buildingFactorAreaValue / earthArea;

  const totalLandPurchaseCostValue = earthArea * landPurchasePricePerSqm;

  const landscapeCostValue = landscapeAreaValue * landscapeCostPerSquareMeter;
  const totalConstructionCostValue =
    totalEffectiveBuildArea * constructionCostPerSqm + landscapeCostValue;

  const totalAfterAdditionalCostValue =
    totalConstructionCostValue + designCost + legalCost + siteCoordinationCost;

  const totalInvestmentValue =
    totalLandPurchaseCostValue + totalAfterAdditionalCostValue;

  const potentialSaleRevenueValue = netEffectiveBuildArea * sellingPricePerSqm;
  const grossProfitMarginValue =
    potentialSaleRevenueValue - totalInvestmentValue;
  const grossProfitMarginRateValue =
    totalInvestmentValue > 0
      ? (grossProfitMarginValue / totalInvestmentValue) * 100
      : 0;

  const totalYearlyRentValue = netEffectiveBuildArea * yearlyRentPerMeterValue;
  const totalYearlyRentValue2 =
    netEffectiveBuildArea * yearlyRentPerMeterValue2;
  const operatingCostValue = 0.05 * totalYearlyRentValue; // 5%
  const netYearlyRentValue = totalYearlyRentValue - operatingCostValue;
  const investmentReturnFromRentValue =
    totalInvestmentValue > 0
      ? (netYearlyRentValue / totalInvestmentValue) * 100
      : 0;

  // ---------------------------
  // 4) Construct the old JSON for each projectType
  // ---------------------------
  let oldData = {};

  if (projectType === "برج تجاري إداري") {
    oldData = {
      مقدمة: introduction,

      العنوان: title,

      تقرير_تحليل_الاستثمار: {
        مقدمة:
          "هذا التحليل الاستثماري المفصل يقيم جدوى وربحية تطوير مشروع برج إداري فاخر في حي النرجس بالرياض. يشمل التحليل استراتيجيات البيع والإيجار، مع النظر في ديناميكيات السوق الحالية وتقديرات التكاليف والإمكانيات الإيرادية.",

        تفاصيل_المشروع: {
          الموقع: "حي النرجس، الرياض",
          مساحة_الأرض_الإجمالية: `${earthArea} متر مربع`,
          نوع_المشروع: "تطوير برج إداري",
          تنظيمات_التخطيط: `يسمح ببناء ${floorsCount} طوابق`,
        },

        معايير_التطوير: {
          نسبة_البناء_للدور_الأرضي: `نسبة البناء للدور الأرضي = ${(
            groundFloorBuildRatio * 100
          ).toFixed(1)}%`,

          نسبة_البناء_للأدوار_المتكررة: `نسبة البناء للأدوار المتكررة = ${(
            repeatedFloorsRatio * 100
          ).toFixed(1)}%`,

          نسبة_البناء_للملحق_العلوي: `نسبة البناء للملحق العلوي = ${(
            roofAnnexRatio * 100
          ).toFixed(1)}%`,

          الطوابق_المقترحة: `${floorsCount}`,

          الطوابق_التجارية_المقترحة: `${firstFloors}`,

          الطوابق_الإدارية_المقترحة: `${floorsCount - firstFloors}`,

          مساحة_البناء_الفعالة_للدور_الأرضي: `مساحة الأرض * نسبة البناء للدور الأرضي = ${earthArea} * ${groundFloorBuildRatio} = ${groundFloorAreaValue.toFixed(
            2
          )}`,

          مساحة_البناء_الفعالة_للمتكرر: `مساحة الأرض * نسبة البناء للأدوار المتكررة = ${earthArea} * ${repeatedFloorsRatio} = ${repeatedFloorAreaValue.toFixed(
            2
          )}`,

          مساحة_البناء_الفعالة_للملحق_العلوي: `مساحة الأدوار المتكررة * نسبة البناء للملحق = ${repeatedFloorAreaValue.toFixed(
            2
          )} * ${roofAnnexRatio} = ${roofAnnexAreaValue.toFixed(2)}`,

          مساحة_البناء_الفعالة_للأدوار_المتكررة: `مساحة المتكرر * ${
            floorsCount - 2
          } = ${repeatedFloorAreaValue.toFixed(2)} * ${
            floorsCount - 2
          } = ${repeatedFloorsTotalArea.toFixed(2)}`,

          نتيجة_مساحة_البناء_الفعالة: `مساحة الدور الأرضي + مساحة الأدوار المتكررة + مساحة الملحق = ${groundFloorAreaValue.toFixed(
            2
          )} + ${repeatedFloorsTotalArea.toFixed(
            2
          )} + ${roofAnnexAreaValue.toFixed(
            2
          )} = ${totalEffectiveBuildArea.toFixed(2)}`,

          مساحة_اللاند_سكيب: `مساحة الأرض - مساحة الدور الأرضي = ${earthArea} - ${groundFloorAreaValue.toFixed(
            2
          )} = ${landscapeAreaValue.toFixed(2)}`,

          المساحة_المشتركة: `نتيجة مساحة البناء الفعالة / 10 = ${totalEffectiveBuildArea.toFixed(
            2
          )} / 10 = ${sharedSpaceArea.toFixed(2)}`,

          صافي_مساحة_البناء_الفعالة: `نتيجة مساحة البناء الفعالة - المساحة المشتركة = ${totalEffectiveBuildArea.toFixed(
            2
          )} - ${sharedSpaceArea.toFixed(2)} = ${netEffectiveBuildArea.toFixed(
            2
          )}`,

          مساحة_معامل_البناء: `مساحة الدور الأرضي + مساحة الأدوار المتكررة = ${groundFloorAreaValue.toFixed(
            2
          )} + ${repeatedFloorsTotalArea.toFixed(
            2
          )} = ${buildingFactorAreaValue.toFixed(2)}`,

          معامل_البناء: `مساحة معامل البناء / مساحة الأرض = ${buildingFactorAreaValue.toFixed(
            2
          )} / ${earthArea} = ${buildingFactorValue.toFixed(2)}`,

          نتيحة_معامل_البناء: `${(buildingFactorValue * 100).toFixed(2)}%`,

          مساحة_البناء_الفعالة_للتجاري: `((مساحة البناء الفعالة للدور الارضي) + (مساحة البناء الفعالة للمتكرر * ${
            firstFloors - 1
          })) * 0.9 = 
            ((${groundFloorAreaValue.toFixed(
              2
            )}) + (${repeatedFloorAreaValue.toFixed(2)} * ${
            firstFloors - 1
          })) * 0.9 = 
            ${(
              (groundFloorAreaValue +
                repeatedFloorAreaValue * (firstFloors - 1)) *
              0.9
            ).toFixed(2)}`,

          نطاق_حجم_الوحدات_التجارية: `من ${propertySizesFrom} متر مربع إلى ${propertySizesTo} متر مربع`,

          الوحدات_التجارية_المقترحة: `مساحة البناء الفعالة للتجاري / ${basePropertySize} = 
            ${(
              (groundFloorAreaValue +
                repeatedFloorAreaValue * (firstFloors - 1)) *
              0.9
            ).toFixed(2)} / ${basePropertySize} = 
            ${(
              ((groundFloorAreaValue +
                repeatedFloorAreaValue * (firstFloors - 1)) *
                0.9) /
              basePropertySize
            ).toFixed(0)}`,

          نتيجة_الوحدات_التجارية_المقترحة: `${(
            ((groundFloorAreaValue +
              repeatedFloorAreaValue * (firstFloors - 1)) *
              0.9) /
            basePropertySize
          ).toFixed(0)}`,

          مساحة_البناء_الفعالة_للإداري: `صافي مساحة البناء الفعالة - مساحة البناء الفعالة للتجاري = 
             ${netEffectiveBuildArea.toFixed(2)} - ${(
            (groundFloorAreaValue +
              repeatedFloorAreaValue * (firstFloors - 1)) *
            0.9
          ).toFixed(2)} = 
             ${(
               netEffectiveBuildArea -
               (groundFloorAreaValue +
                 repeatedFloorAreaValue * (firstFloors - 1)) *
                 0.9
             ).toFixed(2)}`,

          نطاق_حجم_الوحدات_اللإدارية: `من ${secondPropertySizeFrom} متر مربع إلى ${secondPropertySizeTo} متر مربع`,

          الوحدات_اللإدارية_المقترحة: `مساحة البناء الفعالة للإداري / ${secondBasePropertySize} = 
            ${(
              netEffectiveBuildArea -
              (groundFloorAreaValue +
                repeatedFloorAreaValue * (firstFloors - 1)) *
                0.9
            ).toFixed(2)} / ${secondBasePropertySize} = 
            ${(
              (netEffectiveBuildArea -
                (groundFloorAreaValue +
                  repeatedFloorAreaValue * (firstFloors - 1)) *
                  0.9) /
              basePropertySize
            ).toFixed(0)}`,

          نتيجة_الوحدات_اللإدارية_المقترحة: `${(
            (netEffectiveBuildArea -
              (groundFloorAreaValue +
                repeatedFloorAreaValue * (firstFloors - 1)) *
                0.9) /
            basePropertySize
          ).toFixed(0)}`,
        },

        توقعات_التمويل: {
          تكلفة_شراء_الأرض: {
            تكلفة_الشراء_لكل_متر_مربع: `${landPurchasePricePerSqm} ريال سعودي`,

            التكلفة_الكلية: `مساحة_الأرض_الإجمالية * تكلفة_الشراء_لكل_متر_مربع = ${earthArea} * ${landPurchasePricePerSqm}`,

            نتيجة_التكلفة_الكلية: `${totalLandPurchaseCostValue.toLocaleString()} ريال سعودي`,
          },

          تكاليف_البناء: {
            تكلفة_البناء_لكل_متر_مربع: `${constructionCostPerSqm} ريال سعودي`,

            تكلفة_مساحة_اللاند_سكيب: `${landscapeCostPerSquareMeter}  ريال سعودي * ${landscapeAreaValue.toFixed(
              2
            )} متر مربع = ${(
              landscapeCostPerSquareMeter * landscapeAreaValue
            ).toFixed(2)}`,

            مجموع_تكاليف_البناء: `(نتيجة_مساحة_البناء_الفعالة * تكلفة_البناء_لكل_متر_مربع) + تكلفة_مساحة_اللاند_سكيب = (${totalEffectiveBuildArea.toFixed(
              2
            )} * ${constructionCostPerSqm}) + ${landscapeCostValue.toFixed(2)}`,

            نتيجة_مجموع_تكاليف_البناء: `${totalConstructionCostValue.toLocaleString()} ريال سعودي`,

            التكاليف_الإضافية: {
              تصميم_معماري: `${designCost} ريال سعودي`,
              قانوني_وإداري: `${legalCost} ريال سعودي`,
              تنسيق_الموقع: `${siteCoordinationCost} ريال سعودي`,
            },

            المجموع: `مجموع_تكاليف_البناء + تصميم_معماري + قانوني_وإداري + تنسيق_الموقع = ${totalConstructionCostValue.toLocaleString()} + ${designCost} + ${legalCost} + ${siteCoordinationCost}`,

            نتيجة_المجموع: `${totalAfterAdditionalCostValue.toLocaleString()} ريال سعودي`,
          },

          الاستثمار_الكلي: `تكلفة_شراء_الأرض + مجموع_تكاليف_البناء = ${totalLandPurchaseCostValue.toLocaleString()} + ${totalAfterAdditionalCostValue.toLocaleString()}`,

          نتيجة_الاستثمار_الكلي: `${totalInvestmentValue.toLocaleString()} ريال سعودي`,

          توقعات_الإيرادات_من_البيع: {
            سعر_البيع_لكل_متر_مربع: `${sellingPricePerSqm} ريال سعودي`,

            إيرادات_محتملة_من_البيع: `صافي_مساحة_البناء_الفعالة * سعر_البيع_لكل_متر_مربع = ${netEffectiveBuildArea.toFixed(
              2
            )} * ${sellingPricePerSqm}`,

            نتيجة_الإيرادات_المحتملة_من_البيع: `${potentialSaleRevenueValue.toLocaleString()} ريال سعودي`,

            هامش_الربح_الإجمالي: `إيرادات_محتملة_من_البيع - الاستثمار_الكلي = ${potentialSaleRevenueValue.toLocaleString()} - ${totalInvestmentValue.toLocaleString()}`,

            نتيجة_هامش_الربح_الإجمالي: `${grossProfitMarginValue.toLocaleString()} ريال سعودي`,

            نسبة_هامش_الربح_الإجمالي: `هامش_الربح_الإجمالي / الاستثمار_الكلي * 100 = ${grossProfitMarginValue.toLocaleString()} / ${totalInvestmentValue.toLocaleString()} * 100`,

            نتيجة_نسبة_هامش_الربح_الإجمالي: `${grossProfitMarginRateValue.toFixed(
              2
            )}%`,
          },

          توقعات_الإيرادات_من_الإيجار: {
            الإيجار_السنوي_المتوقع_لكل_متر_مربع: `${yearlyRentPerMeterValue.toFixed(
              2
            )} ريال سعودي`,

            الإيجار_السنوي_الكلي: `صافي_مساحة_البناء_الفعالة * الإيجار_السنوي_المتوقع_لكل_متر_مربع`,

            نتيجة_الإيجار_السنوي_الكلي: `${totalYearlyRentValue.toLocaleString()} ريال سعودي`,

            النفقات_التشغيلية: `5% من الإيجار_السنوي_الكلي = ${operatingCostValue.toLocaleString()}`,

            نتيجة_النفقات_التشغيلية: `${operatingCostValue.toLocaleString()} ريال سعودي`,

            صافي_الإيجار_السنوي: `${totalYearlyRentValue.toLocaleString()} - ${operatingCostValue.toLocaleString()}`,

            نتيجة_صافي_الإيجار_السنوي: `${netYearlyRentValue.toLocaleString()} ريال سعودي`,

            عائد_الاستثمار_من_الإيجار: `صافي_الإيجار_السنوي / الاستثمار_الكلي * 100 = ${netYearlyRentValue.toLocaleString()} / ${totalInvestmentValue.toLocaleString()} * 100`,

            نتيجة_عائد_الاستثمار_من_الإيجار: `${investmentReturnFromRentValue.toFixed(
              2
            )}%`,
          },
        },

        تقييم_المخاطر: risks,

        اعتبارات_استراتيجية: strategy,

        ملخص_تنفيذي:
          userExecutiveSummary ||
          "ضع ملخص تفنيذي مناسب هنا ويجب أن يكون مفيدا ومهما للمشروع",

        توصيات: userRecommendations,
      },

      ملخص_تنفيذي:
        userExecutiveSummary ||
        "ضع ملخص تفنيذي مناسب هنا ويجب أن يكون مفيدا ومهما للمشروع",
    };
  } else if (
    projectType === "برج تجاري سكني" ||
    projectType === "مبنى تجاري سكني"
  ) {
    oldData = {
      مقدمة: introduction,

      العنوان: title,

      تقرير_تحليل_الاستثمار: {
        مقدمة:
          "هذا التحليل الاستثماري المفصل يقيم جدوى وربحية تطوير مشروع برج إداري فاخر في حي النرجس بالرياض. يشمل التحليل استراتيجيات البيع والإيجار، مع النظر في ديناميكيات السوق الحالية وتقديرات التكاليف والإمكانيات الإيرادية.",

        تفاصيل_المشروع: {
          الموقع: "حي النرجس، الرياض",
          مساحة_الأرض_الإجمالية: `${earthArea} متر مربع`,
          نوع_المشروع: "تطوير برج إداري",
          تنظيمات_التخطيط: `يسمح ببناء ${floorsCount} طوابق`,
        },

        معايير_التطوير: {
          نسبة_البناء_للدور_الأرضي: `نسبة البناء للدور الأرضي = ${(
            groundFloorBuildRatio * 100
          ).toFixed(1)}%`,

          نسبة_البناء_للأدوار_المتكررة: `نسبة البناء للأدوار المتكررة = ${(
            repeatedFloorsRatio * 100
          ).toFixed(1)}%`,

          نسبة_البناء_للملحق_العلوي: `نسبة البناء للملحق العلوي = ${(
            roofAnnexRatio * 100
          ).toFixed(1)}%`,

          الطوابق_المقترحة: `${floorsCount}`,

          الطوابق_التجارية_المقترحة: `${firstFloors}`,

          الطوابق_الإدارية_المقترحة: `${floorsCount - firstFloors}`,

          مساحة_البناء_الفعالة_للدور_الأرضي: `مساحة الأرض * نسبة البناء للدور الأرضي = ${earthArea} * ${groundFloorBuildRatio} = ${groundFloorAreaValue.toFixed(
            2
          )}`,

          مساحة_البناء_الفعالة_للمتكرر: `مساحة الأرض * نسبة البناء للأدوار المتكررة = ${earthArea} * ${repeatedFloorsRatio} = ${repeatedFloorAreaValue.toFixed(
            2
          )}`,

          مساحة_البناء_الفعالة_للملحق_العلوي: `مساحة الأدوار المتكررة * نسبة البناء للملحق = ${repeatedFloorAreaValue.toFixed(
            2
          )} * ${roofAnnexRatio} = ${roofAnnexAreaValue.toFixed(2)}`,

          مساحة_البناء_الفعالة_للأدوار_المتكررة: `مساحة المتكرر * ${
            floorsCount - 2
          } = ${repeatedFloorAreaValue.toFixed(2)} * ${
            floorsCount - 2
          } = ${repeatedFloorsTotalArea.toFixed(2)}`,

          نتيجة_مساحة_البناء_الفعالة: `مساحة الدور الأرضي + مساحة الأدوار المتكررة + مساحة الملحق = ${groundFloorAreaValue.toFixed(
            2
          )} + ${repeatedFloorsTotalArea.toFixed(
            2
          )} + ${roofAnnexAreaValue.toFixed(
            2
          )} = ${totalEffectiveBuildArea.toFixed(2)}`,

          مساحة_اللاند_سكيب: `مساحة الأرض - مساحة الدور الأرضي = ${earthArea} - ${groundFloorAreaValue.toFixed(
            2
          )} = ${landscapeAreaValue.toFixed(2)}`,

          المساحة_المشتركة: `نتيجة مساحة البناء الفعالة / 10 = ${totalEffectiveBuildArea.toFixed(
            2
          )} / 10 = ${sharedSpaceArea.toFixed(2)}`,

          صافي_مساحة_البناء_الفعالة: `نتيجة مساحة البناء الفعالة - المساحة المشتركة = ${totalEffectiveBuildArea.toFixed(
            2
          )} - ${sharedSpaceArea.toFixed(2)} = ${netEffectiveBuildArea.toFixed(
            2
          )}`,

          مساحة_معامل_البناء: `مساحة الدور الأرضي + مساحة الأدوار المتكررة = ${groundFloorAreaValue.toFixed(
            2
          )} + ${repeatedFloorsTotalArea.toFixed(
            2
          )} = ${buildingFactorAreaValue.toFixed(2)}`,

          معامل_البناء: `مساحة معامل البناء / مساحة الأرض = ${buildingFactorAreaValue.toFixed(
            2
          )} / ${earthArea} = ${buildingFactorValue.toFixed(2)}`,

          نتيحة_معامل_البناء: `${(buildingFactorValue * 100).toFixed(2)}%`,

          مساحة_البناء_الفعالة_للتجاري: `((مساحة البناء الفعالة للدور الارضي) + (مساحة البناء الفعالة للمتكرر * ${
            firstFloors - 1
          })) * 0.9 = 
            ((${groundFloorAreaValue.toFixed(
              2
            )}) + (${repeatedFloorAreaValue.toFixed(2)} * ${
            firstFloors - 1
          })) * 0.9 = 
            ${(
              (groundFloorAreaValue +
                repeatedFloorAreaValue * (firstFloors - 1)) *
              0.9
            ).toFixed(2)}`,

          نطاق_حجم_الوحدات_التجارية: `من ${propertySizesFrom} متر مربع إلى ${propertySizesTo} متر مربع`,

          الوحدات_التجارية_المقترحة: `مساحة البناء الفعالة للتجاري / ${basePropertySize} = 
            ${(
              (groundFloorAreaValue +
                repeatedFloorAreaValue * (firstFloors - 1)) *
              0.9
            ).toFixed(2)} / ${basePropertySize} = 
            ${(
              ((groundFloorAreaValue +
                repeatedFloorAreaValue * (firstFloors - 1)) *
                0.9) /
              basePropertySize
            ).toFixed(0)}`,

          نتيجة_الوحدات_التجارية_المقترحة: `${(
            ((groundFloorAreaValue +
              repeatedFloorAreaValue * (firstFloors - 1)) *
              0.9) /
            basePropertySize
          ).toFixed(0)}`,

          مساحة_البناء_الفعالة_للسكني: `صافي مساحة البناء الفعالة - مساحة البناء الفعالة للتجاري = 
             ${netEffectiveBuildArea.toFixed(2)} - ${(
            (groundFloorAreaValue +
              repeatedFloorAreaValue * (firstFloors - 1)) *
            0.9
          ).toFixed(2)} = 
             ${(
               netEffectiveBuildArea -
               (groundFloorAreaValue +
                 repeatedFloorAreaValue * (firstFloors - 1)) *
                 0.9
             ).toFixed(2)}`,

          نطاق_حجم_الوحدات_السكنية: `من ${secondPropertySizeFrom} متر مربع إلى ${secondPropertySizeTo} متر مربع`,

          الوحدات_السكنية_المقترحة: `مساحة البناء الفعالة للسكني / ${secondBasePropertySize} = 
            ${(
              netEffectiveBuildArea -
              (groundFloorAreaValue +
                repeatedFloorAreaValue * (firstFloors - 1)) *
                0.9
            ).toFixed(2)} / ${secondBasePropertySize} = 
            ${(
              (netEffectiveBuildArea -
                (groundFloorAreaValue +
                  repeatedFloorAreaValue * (firstFloors - 1)) *
                  0.9) /
              basePropertySize
            ).toFixed(0)}`,

          نتيجة_الوحدات_السكنية_المقترحة: `${(
            (netEffectiveBuildArea -
              (groundFloorAreaValue +
                repeatedFloorAreaValue * (firstFloors - 1)) *
                0.9) /
            basePropertySize
          ).toFixed(0)}`,
        },

        توقعات_التمويل: {
          تكلفة_شراء_الأرض: {
            تكلفة_الشراء_لكل_متر_مربع: `${landPurchasePricePerSqm} ريال سعودي`,

            التكلفة_الكلية: `مساحة_الأرض_الإجمالية * تكلفة_الشراء_لكل_متر_مربع = ${earthArea} * ${landPurchasePricePerSqm}`,

            نتيجة_التكلفة_الكلية: `${totalLandPurchaseCostValue.toLocaleString()} ريال سعودي`,
          },

          تكاليف_البناء: {
            تكلفة_البناء_لكل_متر_مربع: `${constructionCostPerSqm} ريال سعودي`,

            تكلفة_مساحة_اللاند_سكيب: `${landscapeCostPerSquareMeter}  ريال سعودي * ${landscapeAreaValue.toFixed(
              2
            )} متر مربع = ${(
              landscapeCostPerSquareMeter * landscapeAreaValue
            ).toFixed(2)}`,

            مجموع_تكاليف_البناء: `(نتيجة_مساحة_البناء_الفعالة * تكلفة_البناء_لكل_متر_مربع) + تكلفة_مساحة_اللاند_سكيب = (${totalEffectiveBuildArea.toFixed(
              2
            )} * ${constructionCostPerSqm}) + ${landscapeCostValue.toFixed(2)}`,

            نتيجة_مجموع_تكاليف_البناء: `${totalConstructionCostValue.toLocaleString()} ريال سعودي`,

            التكاليف_الإضافية: {
              تصميم_معماري: `${designCost} ريال سعودي`,
              قانوني_وإداري: `${legalCost} ريال سعودي`,
              تنسيق_الموقع: `${siteCoordinationCost} ريال سعودي`,
            },

            المجموع: `مجموع_تكاليف_البناء + تصميم_معماري + قانوني_وإداري + تنسيق_الموقع = ${totalConstructionCostValue.toLocaleString()} + ${designCost} + ${legalCost} + ${siteCoordinationCost}`,

            نتيجة_المجموع: `${totalAfterAdditionalCostValue.toLocaleString()} ريال سعودي`,
          },

          الاستثمار_الكلي: `تكلفة_شراء_الأرض + مجموع_تكاليف_البناء = ${totalLandPurchaseCostValue.toLocaleString()} + ${totalAfterAdditionalCostValue.toLocaleString()}`,

          نتيجة_الاستثمار_الكلي: `${totalInvestmentValue.toLocaleString()} ريال سعودي`,

          توقعات_الإيرادات_من_البيع: {
            سعر_البيع_السكني_لكل_متر_مربع: `${sellingPricePerSqm} ريال سعودي`,

            إيرادات_محتملة_من_البيع_للسكني: `صافي_مساحة_البناء_الفعالة * سعر_البيع_السكني_لكل_متر_مربع = ${netEffectiveBuildArea.toFixed(
              2
            )} * ${sellingPricePerSqm}`,

            نتيجة_الإيرادات_المحتملة_من_البيع_للسكني: `${potentialSaleRevenueValue.toLocaleString()} ريال سعودي`,

            سعر_البيع_التجاري_لكل_متر_مربع: `${sellingPricePerSqm2} ريال سعودي`,

            إيرادات_محتملة_من_البيع_للتجاري: `صافي_مساحة_البناء_الفعالة * سعر_البيع_التجاري_لكل_متر_مربع = 
            ${netEffectiveBuildArea.toFixed(2)} * ${sellingPricePerSqm2}`,

            نتيجة_الإيرادات_المحتملة_من_البيع_للتجاري: `${(
              netEffectiveBuildArea * sellingPricePerSqm2
            ).toLocaleString()} ريال سعودي`,

            هامش_الربح_الإجمالي: `إيرادات_محتملة_من_البيع - الاستثمار_الكلي = 
            ${potentialSaleRevenueValue.toLocaleString()} + ${(
              netEffectiveBuildArea * sellingPricePerSqm2
            ).toLocaleString()} - ${totalInvestmentValue.toLocaleString()}`,

            نتيجة_هامش_الربح_الإجمالي: `${(
              potentialSaleRevenueValue +
              netEffectiveBuildArea * sellingPricePerSqm2 -
              totalInvestmentValue
            ).toLocaleString()} ريال سعودي`,

            نسبة_هامش_الربح_الإجمالي: `هامش_الربح_الإجمالي / الاستثمار_الكلي * 100 = 
            ${(
              potentialSaleRevenueValue +
              netEffectiveBuildArea * sellingPricePerSqm2 -
              totalInvestmentValue
            ).toLocaleString()} / ${totalInvestmentValue.toLocaleString()} * 100`,

            نتيجة_نسبة_هامش_الربح_الإجمالي: `${
              (
                (potentialSaleRevenueValue +
                  netEffectiveBuildArea * sellingPricePerSqm2 -
                  totalInvestmentValue) /
                totalInvestmentValue
              ).toLocaleString() * 100
            }%`,
          },

          توقعات_الإيرادات_من_الإيجار: {
            الإيجار_السنوي_المتوقع_للسكني_لكل_متر_مربع: `${yearlyRentPerMeterValue2.toFixed(
              2
            )} ريال سعودي`,

            الإيجار_السنوي_الكلي_للسكني: `صافي_مساحة_البناء_الفعالة * الإيجار_السنوي_المتوقع_للسكني_لكل_متر_مربع`,

            نتيجة_الإيجار_السنوي_الكلي_للسكني: `${totalYearlyRentValue2.toLocaleString()} ريال سعودي`,

            الإيجار_السنوي_المتوقع_للتجاري_لكل_متر_مربع: `${yearlyRentPerMeterValue.toFixed(
              2
            )} ريال سعودي`,

            الإيجار_السنوي_الكلي_للتجاري: `صافي_مساحة_البناء_الفعالة * الإيجار_السنوي_المتوقع_للتجاري_لكل_متر_مربع`,

            نتيجة_الإيجار_السنوي_الكلي_للتجاري: `${totalYearlyRentValue.toLocaleString()} ريال سعودي`,

            النفقات_التشغيلية: `5% من الإيجار_السنوي_الكلي = ${operatingCostValue.toLocaleString()}`,

            نتيجة_النفقات_التشغيلية: `${operatingCostValue.toLocaleString()} ريال سعودي`,

            صافي_الإيجار_السنوي: `${totalYearlyRentValue2.toLocaleString()} + ${totalYearlyRentValue.toLocaleString()} - ${operatingCostValue.toLocaleString()}`,

            نتيجة_صافي_الإيجار_السنوي: `${(
              totalYearlyRentValue2 +
              totalYearlyRentValue -
              operatingCostValue
            ).toLocaleString()} ريال سعودي`,

            عائد_الاستثمار_من_الإيجار: `صافي_الإيجار_السنوي / الاستثمار_الكلي * 100 = 
            ${(
              totalYearlyRentValue2 +
              totalYearlyRentValue -
              operatingCostValue
            ).toLocaleString()} / ${totalInvestmentValue.toLocaleString()} * 100`,

            نتيجة_عائد_الاستثمار_من_الإيجار: `${(
              ((totalYearlyRentValue2 +
                totalYearlyRentValue -
                operatingCostValue) /
                totalInvestmentValue) *
              100
            ).toLocaleString()}%`,
          },
        },

        تقييم_المخاطر: risks,

        اعتبارات_استراتيجية: strategy,

        ملخص_تنفيذي:
          userExecutiveSummary ||
          "ضع ملخص تفنيذي مناسب هنا ويجب أن يكون مفيدا ومهما للمشروع",

        توصيات: userRecommendations,
      },

      ملخص_تنفيذي:
        userExecutiveSummary ||
        "ضع ملخص تفنيذي مناسب هنا ويجب أن يكون مفيدا ومهما للمشروع",
    };
  } else if (projectType === "مجمع سكني") {
    oldData = {
      مقدمة: introduction,

      العنوان: title,

      تقرير_تحليل_الاستثمار: {
        مقدمة:
          "هذا التحليل الاستثماري المفصل يقيم جدوى وربحية تطوير مشروع برج إداري فاخر في حي النرجس بالرياض. يشمل التحليل استراتيجيات البيع والإيجار، مع النظر في ديناميكيات السوق الحالية وتقديرات التكاليف والإمكانيات الإيرادية.",

        تفاصيل_المشروع: {
          الموقع: "حي النرجس، الرياض",
          مساحة_الأرض_الإجمالية: `${earthArea} متر مربع`,
          نوع_المشروع: "تطوير برج إداري",
          تنظيمات_التخطيط: `يسمح ببناء ${floorsCount} طوابق`,
        },

        معايير_التطوير: {
          نسبة_البناء_الفعالة_على_الأرض: `${landBuildRatio * 100}%`,

          مساحة_الأرض_الفعالة_للبناء: `${
            landBuildRatio * 100
          }% * مساحة الأرض الإجمالية = ${
            landBuildRatio * 100
          }% * ${earthArea} = ${(landBuildRatio * earthArea).toLocaleString()}`,

          مساحة_اللاند_سكيب_للكومباوند: `مساحة الأرض الفعالة للبناء - مساحة الأرض الإجمالية = 
          ${earthArea} - ${(landBuildRatio * earthArea).toLocaleString()} = ${(
            earthArea -
            landBuildRatio * earthArea
          ).toLocaleString()}`,

          مساحة_العمارة: `${buildingArea} متر مربع`,

          عدد_العمارات: `مساحة الأرض الفعالة للبناء / مساحة العمارة = ${(
            landBuildRatio * earthArea
          ).toLocaleString()} / ${buildingArea}
           = ${((landBuildRatio * earthArea) / buildingArea).toLocaleString()}`,

          نسبة_البناء_للدور_الأرضي: `نسبة البناء للدور الأرضي = ${(
            groundFloorBuildRatio * 100
          ).toFixed(1)}%`,

          نسبة_البناء_للأدوار_المتكررة: `نسبة البناء للأدوار المتكررة = ${(
            repeatedFloorsRatio * 100
          ).toFixed(1)}%`,

          نسبة_البناء_للملحق_العلوي: `نسبة البناء للملحق العلوي = ${(
            roofAnnexRatio * 100
          ).toFixed(1)}%`,

          الطوابق_المقترحة: `${floorsCount}`,

          مساحة_البناء_الفعالة_للدور_الأرضي: `مساحة العمارة * نسبة البناء للدور الأرضي = ${buildingArea} * ${groundFloorBuildRatio}
           = ${(buildingArea * groundFloorBuildRatio).toFixed(2)}`,

          مساحة_البناء_الفعالة_للمتكرر: `مساحة العمارة * نسبة البناء للأدوار المتكررة = ${buildingArea} * ${repeatedFloorsRatio}
           = ${(buildingArea * repeatedFloorsRatio).toFixed(2)}`,

          مساحة_البناء_الفعالة_للملحق_العلوي: `مساحة الأدوار المتكررة * نسبة البناء للملحق
           = ${(buildingArea * repeatedFloorsRatio).toFixed(
             2
           )} * ${roofAnnexRatio} = ${(
            buildingArea *
            repeatedFloorsRatio *
            roofAnnexRatio
          ).toFixed(2)}`,

          مساحة_البناء_الفعالة_للأدوار_المتكررة: `مساحة البناء الفعالة للمتكرر * ${
            floorsCount - 2
          }
           = ${(buildingArea * repeatedFloorsRatio).toFixed(2)} * ${
            floorsCount - 2
          }
            = ${(
              buildingArea *
              repeatedFloorsRatio *
              (floorsCount - 2)
            ).toFixed(2)}`,

          نتيجة_مساحة_البناء_الفعالة_للعمارة: `مساحة الدور الأرضي + مساحة الأدوار المتكررة + مساحة الملحق 
          = ${(buildingArea * groundFloorBuildRatio).toFixed(2)} + ${(
            buildingArea *
            repeatedFloorsRatio *
            (floorsCount - 2)
          ).toFixed(2)} + ${(
            buildingArea *
            repeatedFloorsRatio *
            roofAnnexRatio
          ).toFixed(2)}
           = ${(
             buildingArea * groundFloorBuildRatio +
             buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
             buildingArea * repeatedFloorsRatio * roofAnnexRatio
           ).toFixed(2)}`,

          المساحة_المشتركة: `نتيجة مساحة البناء الفعالة / 10 = ${(
            buildingArea * groundFloorBuildRatio +
            buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
            buildingArea * repeatedFloorsRatio * roofAnnexRatio
          ).toFixed(2)} / 10
           = ${(
             (buildingArea * groundFloorBuildRatio +
               buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
               buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
             10
           ).toFixed(2)} `,

          صافي_مساحة_البناء_الفعالة: `نتيجة مساحة البناء الفعالة للعمارة - المساحة المشتركة 
          = ${(
            buildingArea * groundFloorBuildRatio +
            buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
            buildingArea * repeatedFloorsRatio * roofAnnexRatio
          ).toFixed(2)}
           - ${(
             (buildingArea * groundFloorBuildRatio +
               buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
               buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
             10
           ).toFixed(2)}
           = ${(
             buildingArea * groundFloorBuildRatio +
             buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
             buildingArea * repeatedFloorsRatio * roofAnnexRatio -
             (buildingArea * groundFloorBuildRatio +
               buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
               buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
               10
           ).toFixed(2)}`,

          إجمالي_صافي_مساحة_البناء_للكومباوند: `${(
            (buildingArea * groundFloorBuildRatio +
              buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
              buildingArea * repeatedFloorsRatio * roofAnnexRatio -
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                10) *
            ((landBuildRatio * earthArea) / buildingArea)
          ).toFixed(2)}`,

          مساحة_معامل_البناء: `مساحة الدور الأرضي + مساحة الأدوار المتكررة 
          = ${(buildingArea * groundFloorBuildRatio).toFixed(2)} + ${(
            buildingArea *
            repeatedFloorsRatio *
            (floorsCount - 2)
          ).toFixed(2)}
           = ${(
             buildingArea * groundFloorBuildRatio +
             buildingArea * repeatedFloorsRatio * (floorsCount - 2)
           ).toFixed(2)}`,

          معامل_البناء: `مساحة معامل البناء / مساحة الأرض 
          = ${(
            buildingArea * groundFloorBuildRatio +
            buildingArea * repeatedFloorsRatio * (floorsCount - 2)
          ).toFixed(2)} / ${earthArea}
           = ${(
             (buildingArea * groundFloorBuildRatio +
               buildingArea * repeatedFloorsRatio * (floorsCount - 2)) /
             earthArea
           ).toFixed(2)}`,

          نتيحة_معامل_البناء: `${(
            ((buildingArea * groundFloorBuildRatio +
              buildingArea * repeatedFloorsRatio * (floorsCount - 2)) /
              earthArea) *
            100
          ).toFixed(2)}%`,

          نطاق_حجم_الوحدات: `من ${propertySizesFrom} متر مربع إلى ${propertySizesTo} متر مربع`,

          الوحدات_المقترحة: `صافي_مساحة_البناء_الفعالة / ${basePropertySize}
           = ${(
             buildingArea * groundFloorBuildRatio +
             buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
             buildingArea * repeatedFloorsRatio * roofAnnexRatio -
             (buildingArea * groundFloorBuildRatio +
               buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
               buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
               10
           ).toFixed(2)} / ${basePropertySize}
            = ${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) /
              basePropertySize
            ).toFixed(0)}`,

          نتيجة_الوحدات_المقترحة_للعمارة: `${(
            (buildingArea * groundFloorBuildRatio +
              buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
              buildingArea * repeatedFloorsRatio * roofAnnexRatio -
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                10) /
            basePropertySize
          ).toFixed(0)}`,
        },

        توقعات_التمويل: {
          تكلفة_شراء_الأرض: {
            تكلفة_الشراء_لكل_متر_مربع: `${landPurchasePricePerSqm} ريال سعودي`,

            التكلفة_الكلية: `مساحة_الأرض_الإجمالية * تكلفة_الشراء_لكل_متر_مربع = ${earthArea} * ${landPurchasePricePerSqm}`,

            نتيجة_التكلفة_الكلية: `${totalLandPurchaseCostValue.toLocaleString()} ريال سعودي`,
          },

          تكاليف_البناء: {
            تكلفة_البناء_لكل_متر_مربع: `${constructionCostPerSqm} ريال سعودي`,

            تكلفة_مساحة_اللاند_سكيب: `${landscapeCostPerSquareMeter}  ريال سعودي * ${(
              earthArea -
              landBuildRatio * earthArea
            ).toLocaleString()} متر مربع
             = ${(
               landscapeCostPerSquareMeter *
               (earthArea - landBuildRatio * earthArea)
             ).toFixed(2)}`,

            مجموع_تكاليف_البناء: `(صافي_مساحة_البناء_الفعالة_للكومباوند * تكلفة_البناء_لكل_متر_مربع) + تكلفة_مساحة_اللاند_سكيب
             = (${(
               (buildingArea * groundFloorBuildRatio +
                 buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                 buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                 (buildingArea * groundFloorBuildRatio +
                   buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                   buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                   10) *
               ((landBuildRatio * earthArea) / buildingArea)
             ).toFixed(2)} * ${constructionCostPerSqm}) + ${(
              landscapeCostPerSquareMeter *
              (earthArea - landBuildRatio * earthArea)
            ).toFixed(2)}`,

            نتيجة_مجموع_تكاليف_البناء: `${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                constructionCostPerSqm +
              landscapeCostPerSquareMeter *
                (earthArea - landBuildRatio * earthArea)
            ).toFixed(2)} ريال سعودي`,

            التكاليف_الإضافية: {
              تصميم_معماري: `${designCost} ريال سعودي`,
              قانوني_وإداري: `${legalCost} ريال سعودي`,
              تنسيق_الموقع: `${siteCoordinationCost} ريال سعودي`,
            },

            المجموع: `مجموع_تكاليف_البناء + تصميم_معماري + قانوني_وإداري + تنسيق_الموقع
             = ${(
               (buildingArea * groundFloorBuildRatio +
                 buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                 buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                 (buildingArea * groundFloorBuildRatio +
                   buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                   buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                   10) *
                 ((landBuildRatio * earthArea) / buildingArea) *
                 constructionCostPerSqm +
               landscapeCostPerSquareMeter *
                 (earthArea - landBuildRatio * earthArea)
             ).toFixed(
               2
             )} + ${designCost} + ${legalCost} + ${siteCoordinationCost}`,

            نتيجة_المجموع: `${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                constructionCostPerSqm +
              landscapeCostPerSquareMeter *
                (earthArea - landBuildRatio * earthArea) +
              designCost +
              legalCost +
              siteCoordinationCost
            ).toFixed(2)} ريال سعودي`,
          },

          الاستثمار_الكلي: `تكلفة_شراء_الأرض + مجموع_تكاليف_البناء = ${totalLandPurchaseCostValue.toLocaleString()} + ${(
            (buildingArea * groundFloorBuildRatio +
              buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
              buildingArea * repeatedFloorsRatio * roofAnnexRatio -
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                10) *
              ((landBuildRatio * earthArea) / buildingArea) *
              constructionCostPerSqm +
            landscapeCostPerSquareMeter *
              (earthArea - landBuildRatio * earthArea) +
            designCost +
            legalCost +
            siteCoordinationCost
          ).toFixed(2)}`,

          نتيجة_الاستثمار_الكلي: `${(
            totalLandPurchaseCostValue +
            ((buildingArea * groundFloorBuildRatio +
              buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
              buildingArea * repeatedFloorsRatio * roofAnnexRatio -
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                10) *
              ((landBuildRatio * earthArea) / buildingArea) *
              constructionCostPerSqm +
              landscapeCostPerSquareMeter *
                (earthArea - landBuildRatio * earthArea) +
              designCost +
              legalCost +
              siteCoordinationCost)
          ).toFixed(2)} ريال سعودي`,

          توقعات_الإيرادات_من_البيع: {
            سعر_البيع_لكل_متر_مربع: `${sellingPricePerSqm} ريال سعودي`,

            إيرادات_محتملة_من_البيع: `إجمالي_صافي_مساحة_البناء_للكومباوند * سعر_البيع_لكل_متر_مربع
             = ${(
               (buildingArea * groundFloorBuildRatio +
                 buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                 buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                 (buildingArea * groundFloorBuildRatio +
                   buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                   buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                   10) *
               ((landBuildRatio * earthArea) / buildingArea)
             ).toFixed(2)} * ${sellingPricePerSqm}`,

            نتيجة_الإيرادات_المحتملة_من_البيع: `${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
              ((landBuildRatio * earthArea) / buildingArea) *
              sellingPricePerSqm
            ).toFixed(2)} ريال سعودي`,

            هامش_الربح_الإجمالي: `إيرادات_محتملة_من_البيع - الاستثمار_الكلي
             = ${(
               (buildingArea * groundFloorBuildRatio +
                 buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                 buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                 (buildingArea * groundFloorBuildRatio +
                   buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                   buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                   10) *
               ((landBuildRatio * earthArea) / buildingArea) *
               sellingPricePerSqm
             ).toFixed(2)}
              - ${(
                totalLandPurchaseCostValue +
                ((buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                  (buildingArea * groundFloorBuildRatio +
                    buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                    buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                    10) *
                  ((landBuildRatio * earthArea) / buildingArea) *
                  constructionCostPerSqm +
                  landscapeCostPerSquareMeter *
                    (earthArea - landBuildRatio * earthArea) +
                  designCost +
                  legalCost +
                  siteCoordinationCost)
              ).toFixed(2)}`,

            نتيجة_هامش_الربح_الإجمالي: `${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                sellingPricePerSqm -
              (totalLandPurchaseCostValue +
                ((buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                  (buildingArea * groundFloorBuildRatio +
                    buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                    buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                    10) *
                  ((landBuildRatio * earthArea) / buildingArea) *
                  constructionCostPerSqm +
                  landscapeCostPerSquareMeter *
                    (earthArea - landBuildRatio * earthArea) +
                  designCost +
                  legalCost +
                  siteCoordinationCost))
            ).toFixed(2)} ريال سعودي`,

            نسبة_هامش_الربح_الإجمالي: `هامش_الربح_الإجمالي / الاستثمار_الكلي * 100
             = ${(
               (buildingArea * groundFloorBuildRatio +
                 buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                 buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                 (buildingArea * groundFloorBuildRatio +
                   buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                   buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                   10) *
                 ((landBuildRatio * earthArea) / buildingArea) *
                 sellingPricePerSqm -
               (totalLandPurchaseCostValue +
                 ((buildingArea * groundFloorBuildRatio +
                   buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                   buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                   (buildingArea * groundFloorBuildRatio +
                     buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                     buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                     10) *
                   ((landBuildRatio * earthArea) / buildingArea) *
                   constructionCostPerSqm +
                   landscapeCostPerSquareMeter *
                     (earthArea - landBuildRatio * earthArea) +
                   designCost +
                   legalCost +
                   siteCoordinationCost))
             ).toFixed(2)} / ${(
              totalLandPurchaseCostValue +
              ((buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                constructionCostPerSqm +
                landscapeCostPerSquareMeter *
                  (earthArea - landBuildRatio * earthArea) +
                designCost +
                legalCost +
                siteCoordinationCost)
            ).toFixed(2)} * 100`,

            نتيجة_نسبة_هامش_الربح_الإجمالي: `${(
              (((buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                sellingPricePerSqm -
                (totalLandPurchaseCostValue +
                  ((buildingArea * groundFloorBuildRatio +
                    buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                    buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                    (buildingArea * groundFloorBuildRatio +
                      buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                      buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                      10) *
                    ((landBuildRatio * earthArea) / buildingArea) *
                    constructionCostPerSqm +
                    landscapeCostPerSquareMeter *
                      (earthArea - landBuildRatio * earthArea) +
                    designCost +
                    legalCost +
                    siteCoordinationCost))) /
                (totalLandPurchaseCostValue +
                  ((buildingArea * groundFloorBuildRatio +
                    buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                    buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                    (buildingArea * groundFloorBuildRatio +
                      buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                      buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                      10) *
                    ((landBuildRatio * earthArea) / buildingArea) *
                    constructionCostPerSqm +
                    landscapeCostPerSquareMeter *
                      (earthArea - landBuildRatio * earthArea) +
                    designCost +
                    legalCost +
                    siteCoordinationCost))) *
              100
            ).toFixed(2)}%`,
          },

          توقعات_الإيرادات_من_الإيجار: {
            الإيجار_السنوي_المتوقع_لكل_متر_مربع: `${yearlyRentPerMeterValue2.toFixed(
              2
            )} ريال سعودي`,

            الإيجار_السنوي_الكلي: `إجمالي_صافي_مساحة_البناء_للكومباوند * الإيجار_السنوي_المتوقع_لكل_متر_مربع`,

            نتيجة_الإيجار_السنوي_الكلي: `${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
              ((landBuildRatio * earthArea) / buildingArea) *
              yearlyRentPerMeterValue2
            ).toFixed(2)} ريال سعودي`,

            النفقات_التشغيلية: `5% من الإيجار_السنوي_الكلي = ${operatingCostValue.toLocaleString()}`,

            نتيجة_النفقات_التشغيلية: `${operatingCostValue.toLocaleString()} ريال سعودي`,

            صافي_الإيجار_السنوي: `${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
              ((landBuildRatio * earthArea) / buildingArea) *
              yearlyRentPerMeterValue2
            ).toFixed(2)} - ${operatingCostValue.toLocaleString()}`,

            نتيجة_صافي_الإيجار_السنوي: `${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                yearlyRentPerMeterValue2 -
              operatingCostValue
            ).toLocaleString()} ريال سعودي`,

            عائد_الاستثمار_من_الإيجار: `صافي_الإيجار_السنوي / الاستثمار_الكلي * 100 = ${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                yearlyRentPerMeterValue2 -
              operatingCostValue
            ).toLocaleString()} / ${(
              totalLandPurchaseCostValue +
              ((buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                constructionCostPerSqm +
                landscapeCostPerSquareMeter *
                  (earthArea - landBuildRatio * earthArea) +
                designCost +
                legalCost +
                siteCoordinationCost)
            ).toFixed(2)} * 100`,

            نتيجة_عائد_الاستثمار_من_الإيجار: `${(
              (((buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                yearlyRentPerMeterValue2 -
                operatingCostValue) /
                (totalLandPurchaseCostValue +
                  ((buildingArea * groundFloorBuildRatio +
                    buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                    buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                    (buildingArea * groundFloorBuildRatio +
                      buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                      buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                      10) *
                    ((landBuildRatio * earthArea) / buildingArea) *
                    constructionCostPerSqm +
                    landscapeCostPerSquareMeter *
                      (earthArea - landBuildRatio * earthArea) +
                    designCost +
                    legalCost +
                    siteCoordinationCost))) *
              100
            ).toFixed(2)}%`,
          },
        },

        تقييم_المخاطر: risks,

        اعتبارات_استراتيجية: strategy,

        ملخص_تنفيذي:
          userExecutiveSummary ||
          "ضع ملخص تفنيذي مناسب هنا ويجب أن يكون مفيدا ومهما للمشروع",

        توصيات: userRecommendations,
      },

      ملخص_تنفيذي:
        userExecutiveSummary ||
        "ضع ملخص تفنيذي مناسب هنا ويجب أن يكون مفيدا ومهما للمشروع",
    };
  } else if (projectType === "فلل") {
    oldData = {
      مقدمة: introduction,

      العنوان: title,

      تقرير_تحليل_الاستثمار: {
        مقدمة:
          "هذا التحليل الاستثماري المفصل يقيم جدوى وربحية تطوير مشروع برج إداري فاخر في حي النرجس بالرياض. يشمل التحليل استراتيجيات البيع والإيجار، مع النظر في ديناميكيات السوق الحالية وتقديرات التكاليف والإمكانيات الإيرادية.",

        تفاصيل_المشروع: {
          الموقع: "حي النرجس، الرياض",
          مساحة_الأرض_الإجمالية: `${earthArea} متر مربع`,
          نوع_المشروع: "تطوير برج إداري",
          تنظيمات_التخطيط: `يسمح ببناء ${floorsCount} طوابق`,
        },

        معايير_التطوير: {
          نسبة_البناء_الفعالة_على_الأرض: `${landBuildRatio * 100}%`,

          مساحة_الأرض_الفعالة_للبناء: `${
            landBuildRatio * 100
          }% * مساحة الأرض الإجمالية = ${
            landBuildRatio * 100
          }% * ${earthArea} = ${(landBuildRatio * earthArea).toLocaleString()}`,

          مساحة_اللاند_سكيب_للكومباوند: `مساحة الأرض الفعالة للبناء - مساحة الأرض الإجمالية = 
          ${earthArea} - ${(landBuildRatio * earthArea).toLocaleString()} = ${(
            earthArea -
            landBuildRatio * earthArea
          ).toLocaleString()}`,

          مساحة_الفيلا: `${villaArea} متر مربع`,

          عدد_الفلل: `مساحة الأرض الفعالة للبناء / مساحة الفيلا = ${(
            landBuildRatio * earthArea
          ).toLocaleString()} / ${buildingArea}
           = ${((landBuildRatio * earthArea) / buildingArea).toLocaleString()}`,

          نسبة_البناء_للدور_الأرضي: `نسبة البناء للدور الأرضي = ${(
            groundFloorBuildRatio * 100
          ).toFixed(1)}%`,

          نسبة_البناء_للأدوار_المتكررة: `نسبة البناء للأدوار المتكررة = ${(
            repeatedFloorsRatio * 100
          ).toFixed(1)}%`,

          نسبة_البناء_للملحق_العلوي: `نسبة البناء للملحق العلوي = ${(
            roofAnnexRatio * 100
          ).toFixed(1)}%`,

          الطوابق_المقترحة: `${floorsCount}`,

          مساحة_البناء_الفعالة_للدور_الأرضي: `مساحة الفيلا * نسبة البناء للدور الأرضي = ${buildingArea} * ${groundFloorBuildRatio}
           = ${(buildingArea * groundFloorBuildRatio).toFixed(2)}`,

          مساحة_البناء_الفعالة_للمتكرر: `مساحة الفيلا * نسبة البناء للأدوار المتكررة = ${buildingArea} * ${repeatedFloorsRatio}
           = ${(buildingArea * repeatedFloorsRatio).toFixed(2)}`,

          مساحة_البناء_الفعالة_للملحق_العلوي: `مساحة الأدوار المتكررة * نسبة البناء للملحق
           = ${(buildingArea * repeatedFloorsRatio).toFixed(
             2
           )} * ${roofAnnexRatio} = ${(
            buildingArea *
            repeatedFloorsRatio *
            roofAnnexRatio
          ).toFixed(2)}`,

          مساحة_البناء_الفعالة_للأدوار_المتكررة: `مساحة البناء الفعالة للمتكرر * ${
            floorsCount - 2
          }
           = ${(buildingArea * repeatedFloorsRatio).toFixed(2)} * ${
            floorsCount - 2
          }
            = ${(
              buildingArea *
              repeatedFloorsRatio *
              (floorsCount - 2)
            ).toFixed(2)}`,

          نتيجة_مساحة_البناء_الفعالة_للفيلا: `مساحة الدور الأرضي + مساحة الأدوار المتكررة + مساحة الملحق 
          = ${(buildingArea * groundFloorBuildRatio).toFixed(2)} + ${(
            buildingArea *
            repeatedFloorsRatio *
            (floorsCount - 2)
          ).toFixed(2)} + ${(
            buildingArea *
            repeatedFloorsRatio *
            roofAnnexRatio
          ).toFixed(2)}
           = ${(
             buildingArea * groundFloorBuildRatio +
             buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
             buildingArea * repeatedFloorsRatio * roofAnnexRatio
           ).toFixed(2)}`,

          المساحة_المشتركة: `نتيجة مساحة البناء الفعالة / 10 = ${(
            buildingArea * groundFloorBuildRatio +
            buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
            buildingArea * repeatedFloorsRatio * roofAnnexRatio
          ).toFixed(2)} / 10
           = ${(
             (buildingArea * groundFloorBuildRatio +
               buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
               buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
             10
           ).toFixed(2)} `,

          صافي_مساحة_البناء_الفعالة: `نتيجة مساحة البناء الفعالة للفيلا - المساحة المشتركة 
          = ${(
            buildingArea * groundFloorBuildRatio +
            buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
            buildingArea * repeatedFloorsRatio * roofAnnexRatio
          ).toFixed(2)}
           - ${(
             (buildingArea * groundFloorBuildRatio +
               buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
               buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
             10
           ).toFixed(2)}
           = ${(
             buildingArea * groundFloorBuildRatio +
             buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
             buildingArea * repeatedFloorsRatio * roofAnnexRatio -
             (buildingArea * groundFloorBuildRatio +
               buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
               buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
               10
           ).toFixed(2)}`,

          إجمالي_صافي_مساحة_البناء_للكومباوند: `${(
            (buildingArea * groundFloorBuildRatio +
              buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
              buildingArea * repeatedFloorsRatio * roofAnnexRatio -
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                10) *
            ((landBuildRatio * earthArea) / buildingArea)
          ).toFixed(2)}`,

          مساحة_معامل_البناء: `مساحة الدور الأرضي + مساحة الأدوار المتكررة 
          = ${(buildingArea * groundFloorBuildRatio).toFixed(2)} + ${(
            buildingArea *
            repeatedFloorsRatio *
            (floorsCount - 2)
          ).toFixed(2)}
           = ${(
             buildingArea * groundFloorBuildRatio +
             buildingArea * repeatedFloorsRatio * (floorsCount - 2)
           ).toFixed(2)}`,

          معامل_البناء: `مساحة معامل البناء / مساحة الأرض 
          = ${(
            buildingArea * groundFloorBuildRatio +
            buildingArea * repeatedFloorsRatio * (floorsCount - 2)
          ).toFixed(2)} / ${earthArea}
           = ${(
             (buildingArea * groundFloorBuildRatio +
               buildingArea * repeatedFloorsRatio * (floorsCount - 2)) /
             earthArea
           ).toFixed(2)}`,

          نتيحة_معامل_البناء: `${(
            ((buildingArea * groundFloorBuildRatio +
              buildingArea * repeatedFloorsRatio * (floorsCount - 2)) /
              earthArea) *
            100
          ).toFixed(2)}%`,
        },

        توقعات_التمويل: {
          تكلفة_شراء_الأرض: {
            تكلفة_الشراء_لكل_متر_مربع: `${landPurchasePricePerSqm} ريال سعودي`,

            التكلفة_الكلية: `مساحة_الأرض_الإجمالية * تكلفة_الشراء_لكل_متر_مربع = ${earthArea} * ${landPurchasePricePerSqm}`,

            نتيجة_التكلفة_الكلية: `${totalLandPurchaseCostValue.toLocaleString()} ريال سعودي`,
          },

          تكاليف_البناء: {
            تكلفة_البناء_لكل_متر_مربع: `${constructionCostPerSqm} ريال سعودي`,

            تكلفة_مساحة_اللاند_سكيب: `${landscapeCostPerSquareMeter}  ريال سعودي * ${(
              earthArea -
              landBuildRatio * earthArea
            ).toLocaleString()} متر مربع
             = ${(
               landscapeCostPerSquareMeter *
               (earthArea - landBuildRatio * earthArea)
             ).toFixed(2)}`,

            مجموع_تكاليف_البناء: `(صافي_مساحة_البناء_الفعالة_للكومباوند * تكلفة_البناء_لكل_متر_مربع) + تكلفة_مساحة_اللاند_سكيب
             = (${(
               (buildingArea * groundFloorBuildRatio +
                 buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                 buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                 (buildingArea * groundFloorBuildRatio +
                   buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                   buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                   10) *
               ((landBuildRatio * earthArea) / buildingArea)
             ).toFixed(2)} * ${constructionCostPerSqm}) + ${(
              landscapeCostPerSquareMeter *
              (earthArea - landBuildRatio * earthArea)
            ).toFixed(2)}`,

            نتيجة_مجموع_تكاليف_البناء: `${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                constructionCostPerSqm +
              landscapeCostPerSquareMeter *
                (earthArea - landBuildRatio * earthArea)
            ).toFixed(2)} ريال سعودي`,

            التكاليف_الإضافية: {
              تصميم_معماري: `${designCost} ريال سعودي`,
              قانوني_وإداري: `${legalCost} ريال سعودي`,
              تنسيق_الموقع: `${siteCoordinationCost} ريال سعودي`,
            },

            المجموع: `مجموع_تكاليف_البناء + تصميم_معماري + قانوني_وإداري + تنسيق_الموقع
             = ${(
               (buildingArea * groundFloorBuildRatio +
                 buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                 buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                 (buildingArea * groundFloorBuildRatio +
                   buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                   buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                   10) *
                 ((landBuildRatio * earthArea) / buildingArea) *
                 constructionCostPerSqm +
               landscapeCostPerSquareMeter *
                 (earthArea - landBuildRatio * earthArea)
             ).toFixed(
               2
             )} + ${designCost} + ${legalCost} + ${siteCoordinationCost}`,

            نتيجة_المجموع: `${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                constructionCostPerSqm +
              landscapeCostPerSquareMeter *
                (earthArea - landBuildRatio * earthArea) +
              designCost +
              legalCost +
              siteCoordinationCost
            ).toFixed(2)} ريال سعودي`,
          },

          الاستثمار_الكلي: `تكلفة_شراء_الأرض + مجموع_تكاليف_البناء = ${totalLandPurchaseCostValue.toLocaleString()} + ${(
            (buildingArea * groundFloorBuildRatio +
              buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
              buildingArea * repeatedFloorsRatio * roofAnnexRatio -
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                10) *
              ((landBuildRatio * earthArea) / buildingArea) *
              constructionCostPerSqm +
            landscapeCostPerSquareMeter *
              (earthArea - landBuildRatio * earthArea) +
            designCost +
            legalCost +
            siteCoordinationCost
          ).toFixed(2)}`,

          نتيجة_الاستثمار_الكلي: `${(
            totalLandPurchaseCostValue +
            ((buildingArea * groundFloorBuildRatio +
              buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
              buildingArea * repeatedFloorsRatio * roofAnnexRatio -
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                10) *
              ((landBuildRatio * earthArea) / buildingArea) *
              constructionCostPerSqm +
              landscapeCostPerSquareMeter *
                (earthArea - landBuildRatio * earthArea) +
              designCost +
              legalCost +
              siteCoordinationCost)
          ).toFixed(2)} ريال سعودي`,

          توقعات_الإيرادات_من_البيع: {
            سعر_البيع_لكل_متر_مربع: `${sellingPricePerSqm} ريال سعودي`,

            إيرادات_محتملة_من_البيع: `إجمالي_صافي_مساحة_البناء_للكومباوند * سعر_البيع_لكل_متر_مربع
             = ${(
               (buildingArea * groundFloorBuildRatio +
                 buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                 buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                 (buildingArea * groundFloorBuildRatio +
                   buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                   buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                   10) *
               ((landBuildRatio * earthArea) / buildingArea)
             ).toFixed(2)} * ${sellingPricePerSqm}`,

            نتيجة_الإيرادات_المحتملة_من_البيع: `${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
              ((landBuildRatio * earthArea) / buildingArea) *
              sellingPricePerSqm
            ).toFixed(2)} ريال سعودي`,

            هامش_الربح_الإجمالي: `إيرادات_محتملة_من_البيع - الاستثمار_الكلي
             = ${(
               (buildingArea * groundFloorBuildRatio +
                 buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                 buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                 (buildingArea * groundFloorBuildRatio +
                   buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                   buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                   10) *
               ((landBuildRatio * earthArea) / buildingArea) *
               sellingPricePerSqm
             ).toFixed(2)}
              - ${(
                totalLandPurchaseCostValue +
                ((buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                  (buildingArea * groundFloorBuildRatio +
                    buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                    buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                    10) *
                  ((landBuildRatio * earthArea) / buildingArea) *
                  constructionCostPerSqm +
                  landscapeCostPerSquareMeter *
                    (earthArea - landBuildRatio * earthArea) +
                  designCost +
                  legalCost +
                  siteCoordinationCost)
              ).toFixed(2)}`,

            نتيجة_هامش_الربح_الإجمالي: `${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                sellingPricePerSqm -
              (totalLandPurchaseCostValue +
                ((buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                  (buildingArea * groundFloorBuildRatio +
                    buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                    buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                    10) *
                  ((landBuildRatio * earthArea) / buildingArea) *
                  constructionCostPerSqm +
                  landscapeCostPerSquareMeter *
                    (earthArea - landBuildRatio * earthArea) +
                  designCost +
                  legalCost +
                  siteCoordinationCost))
            ).toFixed(2)} ريال سعودي`,

            نسبة_هامش_الربح_الإجمالي: `هامش_الربح_الإجمالي / الاستثمار_الكلي * 100
             = ${(
               (buildingArea * groundFloorBuildRatio +
                 buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                 buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                 (buildingArea * groundFloorBuildRatio +
                   buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                   buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                   10) *
                 ((landBuildRatio * earthArea) / buildingArea) *
                 sellingPricePerSqm -
               (totalLandPurchaseCostValue +
                 ((buildingArea * groundFloorBuildRatio +
                   buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                   buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                   (buildingArea * groundFloorBuildRatio +
                     buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                     buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                     10) *
                   ((landBuildRatio * earthArea) / buildingArea) *
                   constructionCostPerSqm +
                   landscapeCostPerSquareMeter *
                     (earthArea - landBuildRatio * earthArea) +
                   designCost +
                   legalCost +
                   siteCoordinationCost))
             ).toFixed(2)} / ${(
              totalLandPurchaseCostValue +
              ((buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                constructionCostPerSqm +
                landscapeCostPerSquareMeter *
                  (earthArea - landBuildRatio * earthArea) +
                designCost +
                legalCost +
                siteCoordinationCost)
            ).toFixed(2)} * 100`,

            نتيجة_نسبة_هامش_الربح_الإجمالي: `${(
              (((buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                sellingPricePerSqm -
                (totalLandPurchaseCostValue +
                  ((buildingArea * groundFloorBuildRatio +
                    buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                    buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                    (buildingArea * groundFloorBuildRatio +
                      buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                      buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                      10) *
                    ((landBuildRatio * earthArea) / buildingArea) *
                    constructionCostPerSqm +
                    landscapeCostPerSquareMeter *
                      (earthArea - landBuildRatio * earthArea) +
                    designCost +
                    legalCost +
                    siteCoordinationCost))) /
                (totalLandPurchaseCostValue +
                  ((buildingArea * groundFloorBuildRatio +
                    buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                    buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                    (buildingArea * groundFloorBuildRatio +
                      buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                      buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                      10) *
                    ((landBuildRatio * earthArea) / buildingArea) *
                    constructionCostPerSqm +
                    landscapeCostPerSquareMeter *
                      (earthArea - landBuildRatio * earthArea) +
                    designCost +
                    legalCost +
                    siteCoordinationCost))) *
              100
            ).toFixed(2)}%`,
          },

          توقعات_الإيرادات_من_الإيجار: {
            الإيجار_السنوي_المتوقع_لكل_متر_مربع: `${yearlyRentPerMeterValue2.toFixed(
              2
            )} ريال سعودي`,

            الإيجار_السنوي_الكلي: `إجمالي_صافي_مساحة_البناء_للكومباوند * الإيجار_السنوي_المتوقع_لكل_متر_مربع`,

            نتيجة_الإيجار_السنوي_الكلي: `${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
              ((landBuildRatio * earthArea) / buildingArea) *
              yearlyRentPerMeterValue2
            ).toFixed(2)} ريال سعودي`,

            النفقات_التشغيلية: `5% من الإيجار_السنوي_الكلي = ${operatingCostValue.toLocaleString()}`,

            نتيجة_النفقات_التشغيلية: `${operatingCostValue.toLocaleString()} ريال سعودي`,

            صافي_الإيجار_السنوي: `${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
              ((landBuildRatio * earthArea) / buildingArea) *
              yearlyRentPerMeterValue2
            ).toFixed(2)} - ${operatingCostValue.toLocaleString()}`,

            نتيجة_صافي_الإيجار_السنوي: `${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                yearlyRentPerMeterValue2 -
              operatingCostValue
            ).toLocaleString()} ريال سعودي`,

            عائد_الاستثمار_من_الإيجار: `صافي_الإيجار_السنوي / الاستثمار_الكلي * 100 = ${(
              (buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                yearlyRentPerMeterValue2 -
              operatingCostValue
            ).toLocaleString()} / ${(
              totalLandPurchaseCostValue +
              ((buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                constructionCostPerSqm +
                landscapeCostPerSquareMeter *
                  (earthArea - landBuildRatio * earthArea) +
                designCost +
                legalCost +
                siteCoordinationCost)
            ).toFixed(2)} * 100`,

            نتيجة_عائد_الاستثمار_من_الإيجار: `${(
              (((buildingArea * groundFloorBuildRatio +
                buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                (buildingArea * groundFloorBuildRatio +
                  buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                  buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                  10) *
                ((landBuildRatio * earthArea) / buildingArea) *
                yearlyRentPerMeterValue2 -
                operatingCostValue) /
                (totalLandPurchaseCostValue +
                  ((buildingArea * groundFloorBuildRatio +
                    buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                    buildingArea * repeatedFloorsRatio * roofAnnexRatio -
                    (buildingArea * groundFloorBuildRatio +
                      buildingArea * repeatedFloorsRatio * (floorsCount - 2) +
                      buildingArea * repeatedFloorsRatio * roofAnnexRatio) /
                      10) *
                    ((landBuildRatio * earthArea) / buildingArea) *
                    constructionCostPerSqm +
                    landscapeCostPerSquareMeter *
                      (earthArea - landBuildRatio * earthArea) +
                    designCost +
                    legalCost +
                    siteCoordinationCost))) *
              100
            ).toFixed(2)}%`,
          },
        },

        تقييم_المخاطر: risks,

        اعتبارات_استراتيجية: strategy,

        ملخص_تنفيذي:
          userExecutiveSummary ||
          "ضع ملخص تفنيذي مناسب هنا ويجب أن يكون مفيدا ومهما للمشروع",

        توصيات: userRecommendations,
      },

      ملخص_تنفيذي:
        userExecutiveSummary ||
        "ضع ملخص تفنيذي مناسب هنا ويجب أن يكون مفيدا ومهما للمشروع",
    };
  } else if (projectType === "فيلا") {
    oldData = {
      مقدمة: introduction,

      العنوان: title,

      تقرير_تحليل_الاستثمار: {
        مقدمة:
          "هذا التحليل الاستثماري المفصل يقيم جدوى وربحية تطوير مشروع برج إداري فاخر في حي النرجس بالرياض. يشمل التحليل استراتيجيات البيع والإيجار، مع النظر في ديناميكيات السوق الحالية وتقديرات التكاليف والإمكانيات الإيرادية.",

        تفاصيل_المشروع: {
          الموقع: "حي النرجس، الرياض",
          مساحة_الأرض_الإجمالية: `${earthArea} متر مربع`,
          نوع_المشروع: "تطوير برج إداري",
          تنظيمات_التخطيط: `يسمح ببناء ${floorsCount} طوابق`,
        },

        معايير_التطوير: {
          نسبة_البناء_للدور_الأرضي: `نسبة البناء للدور الأرضي = ${(
            groundFloorBuildRatio * 100
          ).toFixed(1)}%`,

          نسبة_البناء_للأدوار_المتكررة: `نسبة البناء للأدوار المتكررة = ${(
            repeatedFloorsRatio * 100
          ).toFixed(1)}%`,

          نسبة_البناء_للملحق_العلوي: `نسبة البناء للملحق العلوي = ${(
            roofAnnexRatio * 100
          ).toFixed(1)}%`,

          الطوابق_المقترحة: `${floorsCount}`,

          مساحة_البناء_الفعالة_للدور_الأرضي: `مساحة الأرض * نسبة البناء للدور الأرضي = ${earthArea} * ${groundFloorBuildRatio} = ${groundFloorAreaValue.toFixed(
            2
          )}`,

          مساحة_البناء_الفعالة_للمتكرر: `مساحة الأرض * نسبة البناء للأدوار المتكررة = ${earthArea} * ${repeatedFloorsRatio} = ${repeatedFloorAreaValue.toFixed(
            2
          )}`,

          مساحة_البناء_الفعالة_للملحق_العلوي: `مساحة الأدوار المتكررة * نسبة البناء للملحق = ${repeatedFloorAreaValue.toFixed(
            2
          )} * ${roofAnnexRatio} = ${roofAnnexAreaValue.toFixed(2)}`,

          مساحة_البناء_الفعالة_للأدوار_المتكررة: `مساحة المتكرر * ${
            floorsCount - 2
          } = ${repeatedFloorAreaValue.toFixed(2)} * ${
            floorsCount - 2
          } = ${repeatedFloorsTotalArea.toFixed(2)}`,

          نتيجة_مساحة_البناء_الفعالة: `مساحة الدور الأرضي + مساحة الأدوار المتكررة + مساحة الملحق = ${groundFloorAreaValue.toFixed(
            2
          )} + ${repeatedFloorsTotalArea.toFixed(
            2
          )} + ${roofAnnexAreaValue.toFixed(
            2
          )} = ${totalEffectiveBuildArea.toFixed(2)}`,

          مساحة_اللاند_سكيب: `مساحة الأرض - مساحة الدور الأرضي = ${earthArea} - ${groundFloorAreaValue.toFixed(
            2
          )} = ${landscapeAreaValue.toFixed(2)}`,

          المساحة_المشتركة: `نتيجة مساحة البناء الفعالة / 10 = ${totalEffectiveBuildArea.toFixed(
            2
          )} / 10 = ${sharedSpaceArea.toFixed(2)}`,

          صافي_مساحة_البناء_الفعالة: `نتيجة مساحة البناء الفعالة - المساحة المشتركة = ${totalEffectiveBuildArea.toFixed(
            2
          )} - ${sharedSpaceArea.toFixed(2)} = ${netEffectiveBuildArea.toFixed(
            2
          )}`,

          مساحة_معامل_البناء: `مساحة الدور الأرضي + مساحة الأدوار المتكررة = ${groundFloorAreaValue.toFixed(
            2
          )} + ${repeatedFloorsTotalArea.toFixed(
            2
          )} = ${buildingFactorAreaValue.toFixed(2)}`,

          معامل_البناء: `مساحة معامل البناء / مساحة الأرض = ${buildingFactorAreaValue.toFixed(
            2
          )} / ${earthArea} = ${buildingFactorValue.toFixed(2)}`,

          نتيحة_معامل_البناء: `${(buildingFactorValue * 100).toFixed(2)}%`,
        },

        توقعات_التمويل: {
          تكلفة_شراء_الأرض: {
            تكلفة_الشراء_لكل_متر_مربع: `${landPurchasePricePerSqm} ريال سعودي`,

            التكلفة_الكلية: `مساحة_الأرض_الإجمالية * تكلفة_الشراء_لكل_متر_مربع = ${earthArea} * ${landPurchasePricePerSqm}`,

            نتيجة_التكلفة_الكلية: `${totalLandPurchaseCostValue.toLocaleString()} ريال سعودي`,
          },

          تكاليف_البناء: {
            تكلفة_البناء_لكل_متر_مربع: `${constructionCostPerSqm} ريال سعودي`,

            تكلفة_مساحة_اللاند_سكيب: `${landscapeCostPerSquareMeter}  ريال سعودي * ${landscapeAreaValue.toFixed(
              2
            )} متر مربع = ${(
              landscapeCostPerSquareMeter * landscapeAreaValue
            ).toFixed(2)}`,

            مجموع_تكاليف_البناء: `(نتيجة_مساحة_البناء_الفعالة * تكلفة_البناء_لكل_متر_مربع) + تكلفة_مساحة_اللاند_سكيب = (${totalEffectiveBuildArea.toFixed(
              2
            )} * ${constructionCostPerSqm}) + ${landscapeCostValue.toFixed(2)}`,

            نتيجة_مجموع_تكاليف_البناء: `${totalConstructionCostValue.toLocaleString()} ريال سعودي`,

            التكاليف_الإضافية: {
              تصميم_معماري: `${designCost} ريال سعودي`,
              قانوني_وإداري: `${legalCost} ريال سعودي`,
              تنسيق_الموقع: `${siteCoordinationCost} ريال سعودي`,
            },

            المجموع: `مجموع_تكاليف_البناء + تصميم_معماري + قانوني_وإداري + تنسيق_الموقع = ${totalConstructionCostValue.toLocaleString()} + ${designCost} + ${legalCost} + ${siteCoordinationCost}`,

            نتيجة_المجموع: `${totalAfterAdditionalCostValue.toLocaleString()} ريال سعودي`,
          },

          الاستثمار_الكلي: `تكلفة_شراء_الأرض + مجموع_تكاليف_البناء = ${totalLandPurchaseCostValue.toLocaleString()} + ${totalAfterAdditionalCostValue.toLocaleString()}`,

          نتيجة_الاستثمار_الكلي: `${totalInvestmentValue.toLocaleString()} ريال سعودي`,

          توقعات_الإيرادات_من_البيع: {
            سعر_البيع_لكل_متر_مربع: `${sellingPricePerSqm} ريال سعودي`,

            إيرادات_محتملة_من_البيع: `صافي_مساحة_البناء_الفعالة * سعر_البيع_لكل_متر_مربع = ${netEffectiveBuildArea.toFixed(
              2
            )} * ${sellingPricePerSqm}`,

            نتيجة_الإيرادات_المحتملة_من_البيع: `${potentialSaleRevenueValue.toLocaleString()} ريال سعودي`,

            هامش_الربح_الإجمالي: `إيرادات_محتملة_من_البيع - الاستثمار_الكلي = ${potentialSaleRevenueValue.toLocaleString()} - ${totalInvestmentValue.toLocaleString()}`,

            نتيجة_هامش_الربح_الإجمالي: `${grossProfitMarginValue.toLocaleString()} ريال سعودي`,

            نسبة_هامش_الربح_الإجمالي: `هامش_الربح_الإجمالي / الاستثمار_الكلي * 100 = ${grossProfitMarginValue.toLocaleString()} / ${totalInvestmentValue.toLocaleString()} * 100`,

            نتيجة_نسبة_هامش_الربح_الإجمالي: `${grossProfitMarginRateValue.toFixed(
              2
            )}%`,
          },

          توقعات_الإيرادات_من_الإيجار: {
            الإيجار_السنوي_المتوقع_لكل_متر_مربع: `${yearlyRentPerMeterValue.toFixed(
              2
            )} ريال سعودي`,

            الإيجار_السنوي_الكلي: `صافي_مساحة_البناء_الفعالة * الإيجار_السنوي_المتوقع_لكل_متر_مربع`,

            نتيجة_الإيجار_السنوي_الكلي: `${totalYearlyRentValue.toLocaleString()} ريال سعودي`,

            النفقات_التشغيلية: `5% من الإيجار_السنوي_الكلي = ${operatingCostValue.toLocaleString()}`,

            نتيجة_النفقات_التشغيلية: `${operatingCostValue.toLocaleString()} ريال سعودي`,

            صافي_الإيجار_السنوي: `${totalYearlyRentValue.toLocaleString()} - ${operatingCostValue.toLocaleString()}`,

            نتيجة_صافي_الإيجار_السنوي: `${netYearlyRentValue.toLocaleString()} ريال سعودي`,

            عائد_الاستثمار_من_الإيجار: `صافي_الإيجار_السنوي / الاستثمار_الكلي * 100 = ${netYearlyRentValue.toLocaleString()} / ${totalInvestmentValue.toLocaleString()} * 100`,

            نتيجة_عائد_الاستثمار_من_الإيجار: `${investmentReturnFromRentValue.toFixed(
              2
            )}%`,
          },
        },

        تقييم_المخاطر: risks,

        اعتبارات_استراتيجية: strategy,

        ملخص_تنفيذي:
          userExecutiveSummary ||
          "ضع ملخص تفنيذي مناسب هنا ويجب أن يكون مفيدا ومهما للمشروع",

        توصيات: userRecommendations,
      },

      ملخص_تنفيذي:
        userExecutiveSummary ||
        "ضع ملخص تفنيذي مناسب هنا ويجب أن يكون مفيدا ومهما للمشروع",
    };
  } else {
    // if (projectType === "برج إداري")
    oldData = {
      مقدمة: introduction,

      العنوان: title,

      تقرير_تحليل_الاستثمار: {
        مقدمة:
          "هذا التحليل الاستثماري المفصل يقيم جدوى وربحية تطوير مشروع برج إداري فاخر في حي النرجس بالرياض. يشمل التحليل استراتيجيات البيع والإيجار، مع النظر في ديناميكيات السوق الحالية وتقديرات التكاليف والإمكانيات الإيرادية.",

        تفاصيل_المشروع: {
          الموقع: "حي النرجس، الرياض",
          مساحة_الأرض_الإجمالية: `${earthArea} متر مربع`,
          نوع_المشروع: "تطوير برج إداري",
          تنظيمات_التخطيط: `يسمح ببناء ${floorsCount} طوابق`,
        },

        معايير_التطوير: {
          نسبة_البناء_للدور_الأرضي: `نسبة البناء للدور الأرضي = ${(
            groundFloorBuildRatio * 100
          ).toFixed(1)}%`,

          نسبة_البناء_للأدوار_المتكررة: `نسبة البناء للأدوار المتكررة = ${(
            repeatedFloorsRatio * 100
          ).toFixed(1)}%`,

          نسبة_البناء_للملحق_العلوي: `نسبة البناء للملحق العلوي = ${(
            roofAnnexRatio * 100
          ).toFixed(1)}%`,

          الطوابق_المقترحة: `${floorsCount}`,

          مساحة_البناء_الفعالة_للدور_الأرضي: `مساحة الأرض * نسبة البناء للدور الأرضي = ${earthArea} * ${groundFloorBuildRatio} = ${groundFloorAreaValue.toFixed(
            2
          )}`,

          مساحة_البناء_الفعالة_للمتكرر: `مساحة الأرض * نسبة البناء للأدوار المتكررة = ${earthArea} * ${repeatedFloorsRatio} = ${repeatedFloorAreaValue.toFixed(
            2
          )}`,

          مساحة_البناء_الفعالة_للملحق_العلوي: `مساحة الأدوار المتكررة * نسبة البناء للملحق = ${repeatedFloorAreaValue.toFixed(
            2
          )} * ${roofAnnexRatio} = ${roofAnnexAreaValue.toFixed(2)}`,

          مساحة_البناء_الفعالة_للأدوار_المتكررة: `مساحة المتكرر * ${
            floorsCount - 2
          } = ${repeatedFloorAreaValue.toFixed(2)} * ${
            floorsCount - 2
          } = ${repeatedFloorsTotalArea.toFixed(2)}`,

          نتيجة_مساحة_البناء_الفعالة: `مساحة الدور الأرضي + مساحة الأدوار المتكررة + مساحة الملحق = ${groundFloorAreaValue.toFixed(
            2
          )} + ${repeatedFloorsTotalArea.toFixed(
            2
          )} + ${roofAnnexAreaValue.toFixed(
            2
          )} = ${totalEffectiveBuildArea.toFixed(2)}`,

          مساحة_اللاند_سكيب: `مساحة الأرض - مساحة الدور الأرضي = ${earthArea} - ${groundFloorAreaValue.toFixed(
            2
          )} = ${landscapeAreaValue.toFixed(2)}`,

          المساحة_المشتركة: `نتيجة مساحة البناء الفعالة / 10 = ${totalEffectiveBuildArea.toFixed(
            2
          )} / 10 = ${sharedSpaceArea.toFixed(2)}`,

          صافي_مساحة_البناء_الفعالة: `نتيجة مساحة البناء الفعالة - المساحة المشتركة = ${totalEffectiveBuildArea.toFixed(
            2
          )} - ${sharedSpaceArea.toFixed(2)} = ${netEffectiveBuildArea.toFixed(
            2
          )}`,

          مساحة_معامل_البناء: `مساحة الدور الأرضي + مساحة الأدوار المتكررة = ${groundFloorAreaValue.toFixed(
            2
          )} + ${repeatedFloorsTotalArea.toFixed(
            2
          )} = ${buildingFactorAreaValue.toFixed(2)}`,

          معامل_البناء: `مساحة معامل البناء / مساحة الأرض = ${buildingFactorAreaValue.toFixed(
            2
          )} / ${earthArea} = ${buildingFactorValue.toFixed(2)}`,

          نتيحة_معامل_البناء: `${buildingFactorValue.toFixed(2)}`,

          نطاق_حجم_الوحدات: `من ${propertySizesFrom} متر مربع إلى ${propertySizesTo} متر مربع`,

          الوحدات_المقترحة: `صافي_مساحة_البناء_الفعالة / ${basePropertySize} = ${netEffectiveBuildArea.toFixed(
            2
          )} / ${basePropertySize} = ${(
            netEffectiveBuildArea / basePropertySize
          ).toFixed(0)}`,

          نتيجة_الوحدات_المقترحة: `${(
            netEffectiveBuildArea / basePropertySize
          ).toFixed(0)}`,
        },

        توقعات_التمويل: {
          تكلفة_شراء_الأرض: {
            تكلفة_الشراء_لكل_متر_مربع: `${landPurchasePricePerSqm} ريال سعودي`,

            التكلفة_الكلية: `مساحة_الأرض_الإجمالية * تكلفة_الشراء_لكل_متر_مربع = ${earthArea} * ${landPurchasePricePerSqm}`,

            نتيجة_التكلفة_الكلية: `${totalLandPurchaseCostValue.toLocaleString()} ريال سعودي`,
          },

          تكاليف_البناء: {
            تكلفة_البناء_لكل_متر_مربع: `${constructionCostPerSqm} ريال سعودي`,

            تكلفة_مساحة_اللاند_سكيب: `${landscapeCostPerSquareMeter}  ريال سعودي * ${landscapeAreaValue.toFixed(
              2
            )} متر مربع = ${(
              landscapeCostPerSquareMeter * landscapeAreaValue
            ).toFixed(2)}`,

            مجموع_تكاليف_البناء: `(نتيجة_مساحة_البناء_الفعالة * تكلفة_البناء_لكل_متر_مربع) + تكلفة_مساحة_اللاند_سكيب = (${totalEffectiveBuildArea.toFixed(
              2
            )} * ${constructionCostPerSqm}) + ${landscapeCostValue.toFixed(2)}`,

            نتيجة_مجموع_تكاليف_البناء: `${totalConstructionCostValue.toLocaleString()} ريال سعودي`,

            التكاليف_الإضافية: {
              تصميم_معماري: `${designCost} ريال سعودي`,
              قانوني_وإداري: `${legalCost} ريال سعودي`,
              تنسيق_الموقع: `${siteCoordinationCost} ريال سعودي`,
            },

            المجموع: `مجموع_تكاليف_البناء + تصميم_معماري + قانوني_وإداري + تنسيق_الموقع = ${totalConstructionCostValue.toLocaleString()} + ${designCost} + ${legalCost} + ${siteCoordinationCost}`,

            نتيجة_المجموع: `${totalAfterAdditionalCostValue.toLocaleString()} ريال سعودي`,
          },

          الاستثمار_الكلي: `تكلفة_شراء_الأرض + مجموع_تكاليف_البناء = ${totalLandPurchaseCostValue.toLocaleString()} + ${totalAfterAdditionalCostValue.toLocaleString()}`,

          نتيجة_الاستثمار_الكلي: `${totalInvestmentValue.toLocaleString()} ريال سعودي`,

          توقعات_الإيرادات_من_البيع: {
            سعر_البيع_لكل_متر_مربع: `${sellingPricePerSqm} ريال سعودي`,

            إيرادات_محتملة_من_البيع: `صافي_مساحة_البناء_الفعالة * سعر_البيع_لكل_متر_مربع = ${netEffectiveBuildArea.toFixed(
              2
            )} * ${sellingPricePerSqm}`,

            نتيجة_الإيرادات_المحتملة_من_البيع: `${potentialSaleRevenueValue.toLocaleString()} ريال سعودي`,

            هامش_الربح_الإجمالي: `إيرادات_محتملة_من_البيع - الاستثمار_الكلي = ${potentialSaleRevenueValue.toLocaleString()} - ${totalInvestmentValue.toLocaleString()}`,

            نتيجة_هامش_الربح_الإجمالي: `${grossProfitMarginValue.toLocaleString()} ريال سعودي`,

            نسبة_هامش_الربح_الإجمالي: `هامش_الربح_الإجمالي / الاستثمار_الكلي * 100 = ${grossProfitMarginValue.toLocaleString()} / ${totalInvestmentValue.toLocaleString()} * 100`,

            نتيجة_نسبة_هامش_الربح_الإجمالي: `${grossProfitMarginRateValue.toFixed(
              2
            )}%`,
          },

          توقعات_الإيرادات_من_الإيجار: {
            الإيجار_السنوي_المتوقع_لكل_متر_مربع: `${yearlyRentPerMeterValue.toFixed(
              2
            )} ريال سعودي`,

            الإيجار_السنوي_الكلي: `صافي_مساحة_البناء_الفعالة * الإيجار_السنوي_المتوقع_لكل_متر_مربع`,

            نتيجة_الإيجار_السنوي_الكلي: `${totalYearlyRentValue.toLocaleString()} ريال سعودي`,

            النفقات_التشغيلية: `5% من الإيجار_السنوي_الكلي = ${operatingCostValue.toLocaleString()}`,

            نتيجة_النفقات_التشغيلية: `${operatingCostValue.toLocaleString()} ريال سعودي`,

            صافي_الإيجار_السنوي: `${totalYearlyRentValue.toLocaleString()} - ${operatingCostValue.toLocaleString()}`,

            نتيجة_صافي_الإيجار_السنوي: `${netYearlyRentValue.toLocaleString()} ريال سعودي`,

            عائد_الاستثمار_من_الإيجار: `صافي_الإيجار_السنوي / الاستثمار_الكلي * 100 = ${netYearlyRentValue.toLocaleString()} / ${totalInvestmentValue.toLocaleString()} * 100`,

            نتيجة_عائد_الاستثمار_من_الإيجار: `${investmentReturnFromRentValue.toFixed(
              2
            )}%`,
          },
        },

        تقييم_المخاطر: risks,

        اعتبارات_استراتيجية: strategy,

        ملخص_تنفيذي:
          userExecutiveSummary ||
          "ضع ملخص تفنيذي مناسب هنا ويجب أن يكون مفيدا ومهما للمشروع",

        توصيات: userRecommendations,
      },

      ملخص_تنفيذي:
        userExecutiveSummary ||
        "ضع ملخص تفنيذي مناسب هنا ويجب أن يكون مفيدا ومهما للمشروع",
    };
  }

  // 5) Return the oldData plus the final numeric variables used
  return {
    oldData,
    variables: {
      landPurchasePricePerSqm,
      sellingPricePerSqm,
      sellingPricePerSqm2,
      constructionCostPerSqm,
      floorsCount,
      earthArea,
      firstFloors,
      groundFloorBuildRatio,
      repeatedFloorsRatio,
      roofAnnexRatio,
      propertySizesFrom,
      propertySizesTo,
      basePropertySize,
      secondPropertySizeFrom,
      secondPropertySizeTo,
      secondBasePropertySize,
      landscapeCostPerSquareMeter,
      designCost,
      legalCost,
      siteCoordinationCost,
      yearlyRentPerMeterValue,
      yearlyRentPerMeterValue2,
      landBuildRatio,
      buildingArea,
      villaArea,
    },
  };
}
