import { Link } from "react-router-dom";
import { useLang } from "../../Hooks/LangContext";
import HeaderV2 from "./components/HeaderV2";
import { useRefetch } from "../../Hooks/RefetchContext";
import { animate, delay, motion, useAnimation, useInView } from "framer-motion";
import UserApi from "../../apis/User";
import { useSnackbar } from "../../Hooks/SnackBar";
import { useAuth } from "../../Hooks/authContext";
import {
  IoCheckmarkCircle,
  IoCloudUploadOutline,
  IoColorFillOutline,
} from "react-icons/io5";
import FooterV2 from "./components/FooterV2";
import { LiaPiggyBankSolid } from "react-icons/lia";
import { BsHandThumbsUp } from "react-icons/bs";
import { SlRocket } from "react-icons/sl";
import { FaReact } from "react-icons/fa";
import { useRef } from "react";
export default function LandingPageV2() {
  return (
    <div className="overflow-x-hidden w-full ">
      <div className=" -z-10 absolute    top-0  ">
        <img
          className="object-cover min-w-[1519.2px] min-h-[1140.45px]"
          src="/landingpageElepse.webp"
          alt=""
        />
      </div>

      <motion.div
        className=" -z-10 absolute top-0"
        initial={{ x: "-100%" }}
        animate={{ x: 0 }}
        transition={{ duration: 1.5 }}
      >
        <img
          className="object-cover min-w-[1519.2px] min-h-[1140.45px]"
          src="/gridlineslandingpage.webp"
          alt=""
        />
      </motion.div>
      <motion.div
        initial={{ x: "-100%", opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1.5 }}
        className="rounded-full  top-0   border-none shadow-[0px_0px_1000px_200px_#FFFFFFA6]  absolute  left-0 "
      ></motion.div>
      <motion.div
        initial={{ x: "-100%", opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1.5 }}
        className="rounded-full  top-[400px]   border-none shadow-[0px_0px_1000px_200px_#FFFFFFA6]  absolute  left-0 "
      ></motion.div>
      <motion.div
        initial={{ x: "100%", opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1.5 }}
        className="rounded-full  top-0   border-none shadow-[0px_0px_500px_100px_#FFFFFFA6]  absolute  right-0 "
      ></motion.div>
      <div className="rounded-full  top-[329px]   border-none shadow-[0px_0px_800px_80px_#28148d]  absolute  left-[40%]  "></div>
      <HeaderV2 />
      <div className="min-h-screen relative z-10    overflow-hidden flex flex-col gap-44 py-40">
        <HeroSection />
        <ServicesWeProvide />
        <div className="relative z-10 overflow-hidden bg-[#F2F2FF]">
          <div className="absolute -z-10 translate-x-[70%] -translate-y-[40%] right-0 top-0 bg-[#9A9DFE] w-[620px] h-[620px] md:w-[1102px] md:h-[1341.96px] rounded-full"></div>
          <HighlyAccurateFeasibilityStudy />
        </div>
        <OurPlansSection />
      </div>
      <FooterV2 />
    </div>
  );
}

function HeroSection() {
  const { lang } = useLang();
  const { auth } = useAuth();

  const text =
    lang === "ar" ? "تحليل ذكي لمستقبل" : "Intelligent analysis to the future";
  const letters = text.split("");
  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.05, delayChildren: 0.8 * i },
    }),
  };

  const child = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
    },
  };
  const h3Variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1.5, delay: 0.5 } },
  };
  const imageVariants = {
    hidden: { x: -500, opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 0.5 } },
  };

  return (
    <section
      id="main"
      className="flex text-center gap-20  container mx-auto justify-center  items-center flex-col "
    >
      <div className="flex flex-col gap-2">
        {/* <h1 className="font-bold text-white text-[34px] sm:text-[50px] md:text-[64px]">
          {lang === "ar"
            ? "تحليل ذكي لمستقبل"
            : "Intelligent analysis to the future"}
        </h1> */}
        <h1 className="font-bold text-white text-[34px] sm:text-[50px] md:text-[64px]">
          <motion.span variants={container} initial="hidden" animate="visible">
            {letters.map((letter, index) => (
              <motion.span key={index} variants={child}>
                {letter}
              </motion.span>
            ))}
          </motion.span>
        </h1>
        <motion.div
          className="flex flex-col gap-2"
          initial="hidden"
          animate="visible"
          variants={h3Variants}
        >
          <h3 className="sm:text-[50px] text-[42px] md:text-[64px] text-[#28148d] font-bold">
            {lang === "ar" ? " مشروعك" : "Your project"}
          </h3>

          <p className="text-white  w-[310px] text-[12px] md:text-[16px] md:w-[480px] mx-auto">
            {lang === "ar"
              ? "استفد من قوة التحليل الذكي لإنشاء دراسة جدوى دقيقة وشاملة لمشروعك باستخدام أحدث تقنيات الذكاء الاصطناعي"
              : "Leverage the power of intelligent analysis to create a precise and comprehensive feasibility study for your project using the latest AI technologies."}
          </p>
        </motion.div>
      </div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={h3Variants}
        className="flex gap-5"
      >
        <Link
          to={auth ? "/investmentAinew" : "/singnin"}
          title={lang === "ar" ? "أنشئ دراستك الآن" : "Create your study now."}
          className="bg-main-purple hover:scale-110 duration-500 text-[10px] md:text-[12px] xl:text-[14px] text-[#6A4BFF] font-normal bg-transparent w-[130px]  md:w-[160px]  xl:w-[200px] xl:h-[50px] h-[40px] flex justify-center items-center rounded-[8px] border bg-white"
        >
          {lang === "ar" ? "أنشئ دراستك الآن" : "Create your study now."}
        </Link>
        <Link
          to={"/singnin"}
          title={lang === "ar" ? "عرض المميزات" : "Feature Presentation."}
          className="bg-main-purple text-[10px] hover:scale-110 duration-500 md:text-[12px] xl:text-[14px] font-normal bg-transparent w-[130px]  md:w-[160px]  xl:w-[200px] xl:h-[50px] h-[40px]  flex justify-center items-center rounded-[8px] border text-white"
        >
          {lang === "ar" ? "عرض المميزات" : "Feature Presentation."}
        </Link>
      </motion.div>
      <motion.div initial="hidden" animate="visible" variants={imageVariants}>
        <img src="/createstudy.webp" alt="" />
      </motion.div>
    </section>
  );
}

function ServicesWeProvide() {
  const { lang } = useLang();
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };
  return (
    <section
      dir={lang === "ar" ? "rtl" : "ltr"}
      id="Features"
      className=" text-center flex flex-col md:items-center mt-[200px] sm:mt-0 items-start  gap-20 container px-2 md:px-20 mx-auto"
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }} // Animation triggers only once
        variants={variants}
        className="mx-auto"
      >
        <h2 className="text-[#0C0047] mb-5  font-bold text-[25px] sm:text-[36px]">
          {lang === "ar" ? " الخدمات التي نقدمها" : "The Services We Offer"}
        </h2>

        <p className="text-[#969696] flex flex-col  text-[10px] sm:text-[14px]">
          <span>
            {lang === "ar"
              ? "باستخدام أحدث تقنيات الذكاء الاصطناعي، نقدم لك توصيات مُفصلة وخطط مدروسة تستند إلى البيانات"
              : "Using the latest AI technologies, we provide you with detailed recommendations and well-thought-out plans based on data."}
          </span>
          <span>
            {lang === "ar"
              ? "، لتبدأ مشروعك بثقة كاملة"
              : "to start your project with complete confidence."}
          </span>
        </p>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }} // Animation triggers only once
        variants={variants}
        className="flex [&>section>div]:flex [&>section>div]:flex-col [&>section>div]:gap-5  md:flex-row flex-col  [&>section>div]:mx-auto [&>section>div]:w-[241px]  justify-center items-center md:gap-0 gap-7 lg:gap-7"
      >
        <section
          className={`w-full ${
            lang === "ar" ? "text-right" : "text-left"
          }  flex-col flex gap-10`}
        >
          <div className="">
            <div
              dir={lang === "ar" ? "rtl" : "ltr"}
              className="flex gap-8 items-center"
            >
              <div className="w-[40px] h-[40px] text-[30px] text-[#7642ff] flex justify-center items-center bg-[#F2F2FF] rounded-[10px]">
                <IoColorFillOutline />
              </div>

              <h3 className="text-[#343434] font-semibold text-[22px]">
                {lang === "ar" ? "التعديل بسهولة" : "Easy editing"}
              </h3>
            </div>
            <div className=" text-[14px] text-[#969696]">
              {lang === "ar"
                ? "يمكنك إنشاء وتعديل جميع أنواع الدراسات بناءا على تفضيلاتك وظروف مشروعك"
                : "You can create and customize all types of studies based on your preferences and project circumstances."}
            </div>
          </div>
          <div className="">
            <div
              dir={lang === "ar" ? "rtl" : "ltr"}
              className="flex gap-8 items-center"
            >
              <div className="w-[40px] h-[40px] text-[30px] text-[#7642ff] flex justify-center items-center bg-[#F2F2FF] rounded-[10px]">
                <IoCloudUploadOutline />
              </div>
              <h3 className="text-[#343434] font-semibold text-[22px]">
                {lang === "ar" ? "رفع إلى Cloud" : "Upload to Cloud."}
              </h3>
            </div>

            <div className=" text-[14px] text-[#969696]">
              {lang === "ar"
                ? "سهولة الوصول لجميع الدراسات مع خدمة التخزين السحابي Cloud"
                : "Easy access to all studies with Cloud storage service."}
            </div>
          </div>
        </section>
        <section
          className={`w-full ${
            lang === "ar" ? "text-right" : "text-left"
          }  flex-col flex gap-10`}
        >
          <div className="">
            <div
              dir={lang === "ar" ? "rtl" : "ltr"}
              className="flex gap-8 items-center"
            >
              <div className="w-[40px] h-[40px] text-[30px] text-[#7642ff] flex justify-center items-center bg-[#F2F2FF] rounded-[10px]">
                <SlRocket />
              </div>
              <h3 className="text-[#343434] font-semibold text-[22px]">
                {lang === "ar" ? "سرعة فائقة" : "Fast speed"}
              </h3>
            </div>
            <div className=" text-[14px] text-[#969696]">
              {lang === "ar"
                ? "إنشاء دراسة في وقت  قصير وبدقة عالية"
                : "Creating a study quickly and with high accuracy."}
            </div>
          </div>
          <div className="">
            <div
              dir={lang === "ar" ? "rtl" : "ltr"}
              className="flex gap-8 items-center"
            >
              <div className="w-[40px] h-[40px] text-[30px] text-[#7642ff] flex justify-center items-center bg-[#F2F2FF] rounded-[10px]">
                <FaReact />
              </div>
              <h3 className="text-[#343434] font-semibold text-[22px]">
                {lang === "ar" ? "تقنية مثبتة" : "Proven Technology."}
              </h3>
            </div>
            <div className=" text-[14px] text-[#969696]">
              {lang === "ar"
                ? "يتم إنشاء الدراسة باستخدام احدث تقنيات الذكاء الإصطناعي "
                : "The study is created using the latest AI technologies."}
            </div>
          </div>
        </section>
        <section
          className={`w-full ${
            lang === "ar" ? "text-right" : "text-left"
          }  flex-col flex gap-10`}
        >
          <div className="">
            <div
              dir={lang === "ar" ? "rtl" : "ltr"}
              className="flex gap-8 items-center"
            >
              <div className="w-[40px] h-[40px] text-[40px] text-[#7642ff] flex justify-center items-center bg-[#F2F2FF] rounded-[10px]">
                <LiaPiggyBankSolid />
              </div>
              <h3 className="text-[#343434] font-semibold text-[22px]">
                {lang === "ar" ? "توفير المال" : "Saving Money."}
              </h3>
            </div>
            <div className=" text-[14px] text-[#969696]">
              {lang === "ar"
                ? "بدلا من الإستعانة بخبير وتحمل المزيد من التكاليف يمكنك الإستعانة بموقعنا"
                : "Instead of hiring an expert and incurring additional costs, you can rely on our platform."}
            </div>
          </div>
          <div className="">
            <div
              dir={lang === "ar" ? "rtl" : "ltr"}
              className="flex gap-8 items-center"
            >
              <div className="w-[40px] h-[40px] text-[30px] text-[#7642ff] flex justify-center items-center bg-[#F2F2FF] rounded-[10px]">
                <BsHandThumbsUp />
              </div>
              <h3 className="text-[#343434] font-semibold text-[22px]">
                {lang === "ar" ? "رضا تام" : "Full Satisfaction."}
              </h3>
            </div>
            <div className=" text-[14px] text-[#969696]">
              {lang === "ar"
                ? "نتائج مضمونة ومجربة تجعل تجربة الإستخدام مرضية لجميع المستخدمين"
                : "Guaranteed and tested results that make the user experience satisfying for all users."}
            </div>
          </div>
        </section>
      </motion.div>
    </section>
  );
}

function HighlyAccurateFeasibilityStudy() {
  const { lang } = useLang();
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const containerInView = useInView(containerRef, { once: true });
  const imageInView = useInView(imageRef, { once: true });
  const containerVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 0.5 } },
  };

  const imageVariants = {
    hidden: { x: 100, opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 0.5 } },
  };

  return (
    <section className="flex md:flex-row flex-col-reverse md:py-0 py-20 mid-h-[686px] justify-around items-center  container mx-auto">
      <motion.div
        ref={containerRef}
        initial="hidden"
        animate={containerInView ? "visible" : "hidden"}
        variants={containerVariants}
        className="flex flex-col gap-10"
        dir={lang === "ar" ? "rtl" : "ltr"}
      >
        <h2
          className={`flex ${
            lang === "ar"
              ? "text-[18px] md:text-[30px] xl:text-[52px]"
              : "text-[12px] 2xl:text-[40px] md:text-[20px]"
          }  justify-center  font-bold md:flex-col `}
        >
          <span>
            {lang === "ar"
              ? "دراسة جدوى بدقة عالية"
              : "A feasibility study with high accuracy"}
          </span>
          <span>{lang === "ar" ? "في دقائق فقط!" : "in just minutes!"}</span>
        </h2>
        <p className="text-[#969696] text-[14px]">
          {lang === "ar"
            ? "اكتشف كيف ساعدنا مشاريع مختلفة على النجاح من خلال دراسات جدوى متكاملة"
            : "Discover how we have helped various projects succeed through comprehensive feasibility studies."}
        </p>
        <div className="flex sm:justify-start justify-center">
          <Link
            to={"/singnin"}
            title={lang === "ar" ? "تسجيل الدخول" : "Login"}
            className="bg-[#9A9DFE] text-[14px] font-normal w-[150px] h-[46px] flex justify-center items-center rounded-[8px] border text-white"
          >
            {lang === "ar" ? "جرب الآن" : "try now"}
          </Link>
        </div>
      </motion.div>
      <motion.div
        ref={imageRef}
        initial="hidden"
        animate={imageInView ? "visible" : "hidden"}
        variants={imageVariants}
      >
        <img src="/highquality_study.png" alt="" />
      </motion.div>
    </section>
  );
}

function OurPlansSection() {
  const { lang } = useLang();
  const { auth } = useAuth();
  const { openSnackbar } = useSnackbar();
  const { setRefetch } = useRefetch();

  const Premium = {
    Feature1:
      lang === "ar"
        ? "دراسات جدوى لا نهائية"
        : "Unlimited feasibility studies.",
    Feature2:
      lang === "ar"
        ? "دراسات جدوى لا نهائية"
        : "Unlimited feasibility studies.",
    Feature3:
      lang === "ar"
        ? "دراسات جدوى لا نهائية"
        : "Unlimited feasibility studies.",
    Feature4:
      lang === "ar"
        ? "دراسات جدوى لا نهائية"
        : "Unlimited feasibility studies.",
    Feature5:
      lang === "ar"
        ? "دراسات جدوى لا نهائية"
        : "Unlimited feasibility studies.",
  };

  const Free = {
    Feature1:
      lang === "ar" ? "دراسات جدوى محدودة" : "Limited feasibility studies.",
    Feature2:
      lang === "ar" ? "دراسات جدوى محدودة" : "Limited feasibility studies.",
    Feature3:
      lang === "ar" ? "دراسات جدوى محدودة" : "Limited feasibility studies.",
    Feature4:
      lang === "ar" ? "دراسات جدوى محدودة" : "Limited feasibility studies.",
    Feature5:
      lang === "ar" ? "دراسات جدوى محدودة" : "Limited feasibility studies.",
  };

  const subscribe = () => {
    UserApi.subscribe({ subscription: "Bronze" }, auth)
      .then((res) => {
        console.log(res);
        openSnackbar(
          lang === "ar" ? "تم الإشتراك بنجاح" : "Subscribed successfully"
        );
        setRefetch((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
        openSnackbar(
          lang === "ar"
            ? "حدث خطأ أثناء الإشتراك"
            : "An error occurred while subscribing"
        );
      });
  };

  // Animation variants
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <section
      id="Plans"
      className="flex justify-center gap-32 flex-col items-center"
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }} // Animation triggers only once
        variants={variants}
      >
        <h2
          className={`${
            lang === "ar" ? "flex-row-reverse" : "flex-row"
          } flex font-bold text-nowrap  text-[25px] justify-center md:text-[60px]`}
        >
          {lang === "ar"
            ? "خطط مصممة لتحقيق أهدافك"
            : "Plans designed to achieve your goals."}
        </h2>
      </motion.div>
      <div className="flex md:flex-row flex-col-reverse gap-5 lg:gap-20 mb-10">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }} // Animation triggers only once
          variants={variants}
        >
          <section className="h-full rounded-[10px] mx-auto w-[300px] sm:w-[350px] md:w-[350px] lg:w-[400px] min-h-[490px] gap-10 py-10 shadow-[0px_4px_10.3px_#00000040] flex flex-col items-center border duration-200 hover:scale-[1.01] border-[#C7C7C7] text-center text-black font-bold">
            <div className="text-center flex flex-col gap-5 w-[75%]">
              <h2 className="text-[34px]  md:text-[43px] text-[#6A4BFF]  font-bold">
                {lang === "ar" ? "الخطة المجانية" : "Free plan"}
              </h2>
              <p className="text-[#5B5B5B]">
                {lang === "ar"
                  ? "أحصل على عدد محدود من دراسات الجدوى"
                  : "Get a limited number of feasibility studies."}
              </p>
            </div>
            <h3
              className="flex items-end  gap-1 text-[#6A4BFF]"
              dir={lang === "ar" ? "rtl" : "ltr"}
            >
              <div
                className={`font-bold text-[31px]  ${
                  lang === "ar" ? "flex-row-reverse" : "flex-row-reverse"
                } flex gap-1`}
              >
                <p>{lang === "ar" ? "ريال " : " riyals"}</p>
                <p>20</p>
              </div>
              <p className="font-bold">
                {lang === "ar" ? "\\ شهر" : "/ month"}
              </p>
            </h3>
            <Link
              to={!auth && "/singnin"}
              onClick={auth && subscribe}
              className="bg-[#9A9DFE] text-white text-[20px] font-bold w-[85%] h-[45px] flex justify-center duration-200 hover:shadow-[4px_4px_17.5px_0px_#00000040] items-center p-[10px] rounded-[8px]"
            >
              {lang === "ar" ? "إختيار الخطة" : "Choose the plan."}
            </Link>
            <ul
              className={`flex flex-col ${
                lang === "ar" ? "ml-auto mr-10" : "mr-auto ml-10"
              }  gap-6`}
            >
              {Object.values(Free).map((e, i) => (
                <li
                  className={`text-[14px] font-medium flex items-center gap-2 ${
                    lang === "ar" ? "flex-row" : "flex-row-reverse"
                  }`}
                  key={i}
                >
                  {e}
                  <IoCheckmarkCircle className="text-[#DFDFDF] text-[25px]" />
                </li>
              ))}
            </ul>
          </section>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }} // Animation triggers only once
          variants={variants}
        >
          {/* background: linear-gradient(180deg,  0%,  100%); */}

          <section className="h-full bg-gradient-to-b  mx-auto w-[300px] sm:w-[350px] from-[#6A4BFF] to-[#9A9DFE] rounded-[15px]  md:w-[350px] lg:w-[400px] min-h-[490px] gap-10 py-10 shadow-[0px_4px_10.3px_#00000040] flex flex-col items-center  duration-200 hover:scale-[1.01] text-white">
            <div className="text-center flex flex-col gap-5 w-[75%]">
              <h2 className="text-[34px]  md:text-[43px] font-bold">
                {lang === "ar" ? "الخطة المميزة" : "Premium plan"}
              </h2>
              <p>
                {lang === "ar"
                  ? "أحصل على عدد لا نهائي من دراسات الجدوى مع العديد من المميزات الاخرى"
                  : "Get an unlimited number of feasibility studies with many other features."}
              </p>
            </div>

            <h3
              className="flex items-end  gap-1"
              dir={lang === "ar" ? "rtl" : "ltr"}
            >
              <div
                className={`font-bold text-[31px] ${
                  lang === "ar" ? "flex-row-reverse" : "flex-row-reverse"
                } flex gap-1`}
              >
                <p>{lang === "ar" ? "ريال " : " riyals"}</p>
                <p>20</p>
              </div>
              <p className="font-bold">
                {lang === "ar" ? "\\ شهر" : "/ month"}
              </p>
            </h3>
            {/* <h3>{lang === "ar" ? "20 ريال / شهر" : "20 riyals / month"}</h3> */}
            <Link
              to={!auth && "/singnin"}
              onClick={auth && subscribe}
              className="bg-white text-[#152C5B] text-[20px] font-bold w-[85%] h-[45px] flex justify-center duration-200 hover:shadow-[4px_4px_17.5px_0px_#00000040] items-center p-[10px] rounded-[8px]"
            >
              {lang === "ar" ? "إختيار الخطة" : "Choose the plan."}
            </Link>
            <ul
              className={`flex flex-col ${
                lang === "ar" ? "ml-auto mr-10" : "mr-auto ml-10"
              }  gap-6`}
            >
              {Object.values(Premium).map((e, i) => (
                <li
                  className={`text-[14px] font-medium flex items-center gap-2 ${
                    lang === "ar" ? "flex-row" : "flex-row-reverse"
                  }`}
                  key={i}
                >
                  {e}
                  <IoCheckmarkCircle className="text-[25px]" />
                </li>
              ))}
            </ul>
          </section>
        </motion.div>
      </div>
    </section>
  );
}
