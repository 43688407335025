import { Link, useLocation } from "react-router-dom";
import { useLang } from "../../../Hooks/LangContext";
import { useAuth } from "../../../Hooks/authContext";
import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { IoPersonSharp } from "react-icons/io5";
import AccountMenu from "./AccountMenu";
export default function HeaderV2() {
  const { lang, setLang } = useLang();
  const { auth, account } = useAuth();
  const [openMenu, setOpenMenu] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 767px)").matches
  );
  const location = useLocation();

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  function changeLang() {
    if (lang === "ar") {
      setLang("en");
    } else {
      setLang("ar");
    }
  }
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(min-width: 768px)").matches) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    const activeElement = document.querySelector(
      `a[href='${location.pathname}']`
    );
    setActiveLink(activeElement);
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 767px)").matches);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header
      dir={lang === "ar" ? "ltr" : "rtl"}
      ref={navRef}
      className={` fixed top-0 z-50  ${
        isScrolled ? "bg-[#0000005f] " : ""
      }  duration-500 flex justify-between  items-center  w-full 
       font-bold  h-[80px] md:h-[100px] py-4 px-10 xl:pl-[153px] xl:pr-[93px] xl:py-8 lg:pl-[80px] lg:pr-[60px] lg:py-8`}
    >
      <aside className="flex items-center xl:gap-14  lg:gap-8 gap-4">
        <button
          className="md:text-[12px] xl:text-[14px] text-white"
          onClick={changeLang}
        >
          {lang === "ar" ? "En" : "Ar"}
        </button>
        {auth && !isMobile ? (
          <button
            onClick={toggleMenu}
            className="w-[45px] relative  md:flex hidden h-[45px] rounded-full bg-gradient-to-t from-main-blue to-main-purple  justify-center items-center group"
          >
            <div
              className={`bg-white overflow-clip relative w-[41px] h-[41px] rounded-full text-gray-border group-hover:bg-gradient-to-t from-blue-600 to-purple-600 group-hover:text-white duration-300 ${
                openMenu &&
                "bg-gradient-to-t from-main-blue to-main-purple text-[#fff]"
              }`}
            >
              {account?.photoLink ? (
                <img
                  src={account.photoLink}
                  alt="profile"
                  className="w-full h-full object-fill rounded-full"
                />
              ) : (
                <>
                  <IoPersonSharp size={41} className="absolute -bottom-1" />
                </>
              )}
            </div>
            <AnimatePresence>
              {openMenu && <AccountMenu close={() => setOpenMenu(false)} />}
            </AnimatePresence>
          </button>
        ) : (
          <div className="hidden md:flex gap-5">
            <Link
              to={"/singnin"}
              title={lang === "ar" ? "تسجيل الدخول" : "Login"}
              className="bg-main-purple text-[10px] hover:scale-110 duration-500 md:text-[12px] xl:text-[14px] font-normal bg-transparent w-[100px]  md:w-[120px]  xl:w-[150px] h-[37px] xl:h-[46px] flex justify-center items-center rounded-[8px] border text-white"
            >
              {lang === "ar" ? "تسجيل الدخول " : "Log in"}
            </Link>
            <Link
              to={"/signup"}
              title={lang === "ar" ? "تسجيل الدخول" : "Log in"}
              className="bg-main-purple relative group text-[10px] hover:text-white  
              overflow-hidden md:text-[12px] xl:text-[14px] font-normal  w-[100px]  md:w-[120px] 
               xl:w-[150px] h-[37px] xl:h-[46px]  hover:scale-110 duration-500 flex justify-center items-center rounded-[8px] border  text-[#6A4BFF]"
            >
              {lang === "ar" ? "حساب جديد" : "Sign up"}
              <span className="group-hover:w-0 w-full  absolute top-0 left-0  h-full duration-300 -z-10 bg-white"></span>
            </Link>
          </div>
        )}
      </aside>
      <aside className="hidden md:flex md:text-[12px] xl:text-[14px] !text-white items-center gap-5  relative">
        <div className="group">
          <a href={"#Features"}>{lang === "ar" ? "المميزات" : "Features"}</a>
          <div className="h-[2px] w-[0px] rounded-full bg-white duration-300 group-hover:w-full"></div>
        </div>
        <div className="group">
          <a href="#Plans">{lang === "ar" ? "الخطط" : "Plans"}</a>
          <div className="h-[2px] w-[0px] rounded-full bg-white duration-300 group-hover:w-full"></div>
        </div>
        <div className="group">
          <Link onClick={() => window.scrollTo(0, 0)} to={"/"}>
            {lang === "ar" ? "الرئيسية" : "Main"}
          </Link>
          <div className="h-[2px] w-[0px] rounded-full bg-white duration-300 group-hover:w-full"></div>
        </div>
      </aside>
      <Link
        onClick={() => window.scrollTo(0, 0)}
        className="md:hidden block w-12"
        to={"/"}
      >
        <img src="/icon-grayscale.png" className="object-cover" alt="" />
      </Link>
      <Link
        className="md:block hidden  xl:w-[239px] md:w-[153px]"
        to={"/"}
        onClick={() => window.scrollTo(0, 0)}
      >
        <img
          src="/realininvestmentheader.webp"
          className="object-cover"
          alt=""
        />
      </Link>
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="md:hidden block text-white"
      >
        {auth && isMobile ? (
          <button
            onClick={toggleMenu}
            className="w-[45px] relative  md:hidden flex h-[45px] rounded-full bg-gradient-to-t from-main-blue to-main-purple  justify-center items-center group"
          >
            <div
              className={`bg-white overflow-clip relative w-[41px] h-[41px] rounded-full text-gray-border group-hover:bg-gradient-to-t from-blue-600 to-purple-600 group-hover:text-white duration-300 ${
                openMenu &&
                "bg-gradient-to-t from-main-blue to-main-purple text-[#fff]"
              }`}
            >
              {account?.photoLink ? (
                <img
                  src={account.photoLink}
                  alt="profile"
                  className="w-full h-full object-fill rounded-full"
                />
              ) : (
                <IoPersonSharp size={41} className="absolute text-black hover:text-white duration-300 -bottom-1" />
              )}
            </div>
            <AnimatePresence>
              {openMenu && <AccountMenu close={() => setOpenMenu(false)} />}
            </AnimatePresence>
          </button>
        ) : (
          <div className="md:hidden flex gap-5">
            <Link
              to={"/singnin"}
              title={lang === "ar" ? "تسجيل الدخول" : "Login"}
              className="bg-main-purple relative group text-[10px] hover:text-[#6A4BFF] duration-300 
              overflow-hidden md:text-[12px] xl:text-[14px] font-normal bg-transparent w-[100px]  md:w-[120px] 
               xl:w-[150px] h-[37px] xl:h-[46px] flex justify-center items-center rounded-[8px] border text-white"
            >
              {lang === "ar" ? "تسجيل الدخول " : "Log in"}
              <span className="group-hover:w-full w-0 absolute top-0 left-0  h-full duration-300 -z-10 bg-white"></span>
            </Link>
          </div>
        )}
      </button>
      <div className="fixed !-z-50">
        {/* <SideBar isSidebarOpen={isSidebarOpen} /> */}
      </div>
    </header>
  );
}
