export const getImagePrompt = ({
  type,
  floors,
  apartmentsPerFloor,
  landArea,
  city,
  neighborhood,
  propertyType,
  customPropertyType,
}) => {
  const getAngleDescription = () =>
    "a very high vantage point, at a precise top-down angle of 30 degrees, ensuring the building, its land area, and the surrounding cityscape are fully visible.";

  const angleDescription = getAngleDescription();

  switch (type) {
    case "سكني":
      return `An ultra-high-resolution, photorealistic 3D render of a modern one-sided residential building in the urban heart of ${city}. The building has exactly ${floors} floors (a ground floor and ${floors - 1} upper floors), with the ground floor occupying 60% of the ${landArea} sqm plot and the upper floors occupying 70% (${(landArea * 0.7).toFixed(
        0
      )} sqm each). The facade should feature a dynamic combination of materials such as metal, wood, concrete, brick, or stone for visual variety and modern aesthetics. The building is enclosed by a low wall and includes a parking area with several cars for functionality. The render must emphasize the building’s scale relative to nearby mid-rise and high-rise city buildings and prominently feature Al Riyadh Tower in the background. Include surrounding urban elements such as roads, intersections, and pathways to create a vibrant cityscape. The viewpoint should be from ${angleDescription}, vividly portraying the building's modern design and its seamless placement within the dynamic and bustling city environment of ${city}.`;
    
    case "تجاري":
      return `An ultra-high-resolution, photorealistic 3D render of a sleek, modern commercial building in ${city}. The building consists of ${floors} floors, featuring a facade with dynamic materials such as glass, steel, and concrete. The land area is ${landArea} sqm, with clear property boundaries and bustling streets nearby. The render must prominently feature Al Riyadh Tower and surrounding mid-rise buildings. The viewpoint should be from ${angleDescription}, showcasing the building’s integration into the vibrant commercial environment.`;

    case "تجاري سكني":
      return `An ultra-high-resolution, photorealistic 3D render of a mixed-use building in ${city}. The building combines retail spaces on the ground floor and ${floors - 1} upper floors of residential units, with the ground floor occupying 60% of the ${landArea} sqm plot and the upper floors occupying 70% (${(landArea * 0.7).toFixed(
        0
      )} sqm each). The facade should blend glass storefronts with brick and concrete for the residential levels. The render must emphasize the building’s dual-purpose nature and prominently feature Al Riyadh Tower in the background. The viewpoint should be from ${angleDescription}, vividly portraying the building's integration into the urban landscape.`;

    case "مول تجاري":
      return `An ultra-high-resolution, photorealistic 3D render of a large, modern shopping mall in ${city}. The building consists of ${floors} expansive floors, with a total land area of ${landArea} sqm. The facade should feature glass storefronts and steel accents. The render must include parking lots, surrounding streets, and Al Riyadh Tower for context. The viewpoint should be from ${angleDescription}, vividly portraying the dynamic commercial environment.`;

    case "فلل":
      return `An ultra-high-resolution, photorealistic 3D render of a gated community of luxurious villas in ${city}. Each villa has ${floors} floors, with private gardens and pools. The land area spans ${landArea} sqm, divided into individual plots with clear boundaries. The render must emphasize shared amenities, including parks and pathways, with Al Riyadh Tower visible in the background. The viewpoint should be from ${angleDescription}, vividly portraying the luxury and exclusivity of the villas.`;

    case "إداري":
      return `An ultra-high-resolution, photorealistic 3D render of a formal administrative building in ${city}. The building consists of ${floors} floors, with a sleek facade featuring glass, steel, and concrete. The land area is ${landArea} sqm, including parking spaces and adjacent streets. The render must prominently feature Al Riyadh Tower and the surrounding urban environment. The viewpoint should be from ${angleDescription}, vividly portraying the building’s modern and professional design.`;

    case "فندقي":
      return `An ultra-high-resolution, photorealistic 3D render of a luxurious hotel in ${city}. The building consists of ${floors} floors, with a land area of ${landArea} sqm. The facade should blend modern materials such as glass and stone, with visible amenities like pools and gardens. The render must prominently feature Al Riyadh Tower and surrounding cityscape. The viewpoint should be from ${angleDescription}, vividly portraying the hotel’s luxury and urban setting.`;

    case "أخرى":
      return `An ultra-high-resolution, photorealistic 3D render of a unique ${customPropertyType} building in ${city}. The building consists of ${floors} floors, with a land area of ${landArea} sqm. The facade should feature innovative materials to emphasize its distinct design. The render must showcase the building’s scale relative to surrounding urban structures, with Al Riyadh Tower in the background. The viewpoint should be from ${angleDescription}, vividly portraying the property’s integration into the bustling cityscape.`;

    default:
      return `An ultra-high-resolution, photorealistic 3D render of a modern building in ${city}. The building consists of ${floors} floors, with a land area of ${landArea} sqm. The design should showcase modern architecture and emphasize the building’s integration into the urban environment. The viewpoint should be from ${angleDescription}, vividly portraying the property’s design and surroundings.`;
  }
};
