import { Link } from "react-router-dom";
import { FaTwitter, FaLinkedinIn, FaFacebookF } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { useLang } from "../../../Hooks/LangContext";
export default function FooterV2() {
  const { pathname } = useLocation();
  const { lang } = useLang();
  return (
    <footer
      dir={lang === "ar" ? "rtl" : "ltr"}
      className={`bg-slate-200 ${
        lang === "ar"
          ? "text-right lg:flex-row-reverse"
          : "text-left lg:flex-row-reverse"
      } flex text-black ${
        (pathname === "/dashboard" ||
          pathname === "/Messages" ||
          pathname === "/investoroffer" ||
          pathname === "/dashboardmyprojects" ||
          pathname === "/create-project") &&
        "!hidden"
      }  w-full flex-col items-start justify-center gap-10 px-10 py-5 text-[20.4px] font-bold md:flex-col-reverse md:items-center md:justify-between md:py-24 lg:gap-0 lg:px-32 `}
    >
      <aside
        dir={lang === "ar" ? "rtl" : "ltr"}
        className="flex flex-col gap-5 max-md:mt-4 max-md:items-start"
      >
        <h4 className="ml-auto  w-full">
          {lang === "ar" ? "الشركة" : "Company"}
        </h4>
        <div className="flex flex-col gap-3 text-nowrap text-[16px] font-medium max-md:flex-col max-md:items-start md:flex-row [&>a]:md:mx-auto">
          <Link className="group" to={"/terms"}>
            {lang === "ar" ? "الشركة" : "Company."}
            <div className="h-[2px] w-[0px] rounded-full bg-black duration-300 group-hover:w-full"></div>
          </Link>
          <Link className="group" to={"/privacy"}>
            {lang === "ar" ? "الخدمات" : "Services."}
            <div className="h-[2px] w-[0px] rounded-full bg-black duration-300 group-hover:w-full"></div>
          </Link>
          <Link className="group" to={"/complaint"}>
            {lang === "ar" ? " عنا" : "About Us."}
            <div className="h-[2px] w-[0px] rounded-full bg-black duration-300 group-hover:w-full"></div>
          </Link>
        </div>
      </aside>
      <aside
        dir={lang === "ar" ? "rtl" : "ltr"}
        className="flex flex-col gap-5 max-md:mt-4 max-md:items-start"
      >
        <h4 className="ml-auto text-[#343434]">
          {lang === "ar" ? "روابط سريعة" : "Quick Links"}
        </h4>
        <div className="flex flex-col text-[#343434] gap-3 text-nowrap text-[16px] font-medium max-md:flex-col max-md:items-start md:flex-row [&>a]:md:mx-auto">
          <Link className="group" to={"/terms"}>
            {lang === "ar" ? " الدعم" : "Support."}
            <div className="h-[2px] w-[0px] rounded-full bg-black duration-300 group-hover:w-full"></div>
          </Link>
          <Link className="group" to={"/privacy"}>
            {lang === "ar" ? "الخصوصية" : "Privacy"}
            <div className="h-[2px] w-[0px] rounded-full bg-black duration-300 group-hover:w-full"></div>
          </Link>
          <Link className="group" to={"/complaint"}>
            {lang === "ar" ? "الشروط والأحكام" : "Terms and Conditions."}
            <div className="h-[2px] w-[0px] rounded-full bg-black duration-300 group-hover:w-full"></div>
          </Link>
        </div>
      </aside>
      <aside
        dir={lang === "ar" ? "rtl" : "ltr"}
        className="flex flex-col gap-5 max-md:items-start"
      >
        <Link to={"/"}>
          <img
            src="/RealinfotterLogo.webp"
            className="ml-auto w-fit max- md:ml-auto  md:object-cover"
            alt=""
          />
        </Link>
        <p
          dir={lang === "ar" ? "rtl" : "ltr"}
          className="text-[16px] font-bold text-[#767676]"
        >
          {lang === "ar"
            ? "تحليل ذكي لمستقبل مشروعك"
            : "Smart analysis for the future of your project."}
        </p>
      </aside>
    </footer>
  );
}
